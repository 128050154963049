import { Language } from "../languages/labels/language.labels"

export function appLanguageList(): {code: string, description: string}[] {
  return [
    { code: 'english', description: 'English' },
    { code: 'french', description: 'Français' },
    { code: 'spanish', description: 'Español' }
  ]
}

export function appLanguageValue(lang: Language, code: string): string {
  const language = appLanguageList().find(item => item.code === code)
  return language ? language.description : lang.labels.undefined
}

export function appLanguageUpload(dispatch_language: any): string {
  let idiom: any = 'english'

  if (localStorage.getItem('language')) {
    idiom = localStorage.getItem('language')
  } else {
    localStorage.setItem('language', idiom)
  }

  dispatch_language({type: idiom})

  return idiom
}

export function appLanguageValidate(param_language: any): boolean {
  if (param_language) {
    if (param_language === 'en' || param_language === 'fr' || param_language === 'es') {
      return true
    } else {
      return false
    }
  } else {
    return true
  }
}

export function appLanguageSave(param_language: any) {
  if (param_language) {
    switch (param_language) {
      case 'en':
        localStorage.setItem('language', 'english')
        break;
      case 'fr':
        localStorage.setItem('language', 'french')
        break;
      case 'es':
        localStorage.setItem('language', 'spanish')
        break;
      default:
        if (!localStorage.getItem('language')) {
          localStorage.setItem('language', '')
        }
        break;
    }
  } else if (!localStorage.getItem('language')) {
    localStorage.setItem('language', '')
  }
}
