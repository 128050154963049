import React, { useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

import { UseOutletContextContact } from './Contact';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError } from '../../../languages/translations/response';
import { ContactDA } from '../../../services/contact.service';
import { Error } from '../../../models/error.model';
import { Contact } from '../../../models/contact.model';

import AppContactCreateStepper from './card/ContactCreateStepper';

export interface ContactCreateProps {};

let errorResponse: Error, contactResponse: Contact;

const ContactCreatePage: React.FunctionComponent<ContactCreateProps> = props => {
  const {setRoute, userLoggedIn, companyForUser, loadContactsForCompany} = UseOutletContextContact()
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [loadIndicator, setLoadIndicator] = useState('off')
  const [uuid, setUuid] = useState({value: '', valid: true})
  const [type, setType] = useState({value: '', valid: false})
  const [name, setName] = useState({value: '', valid: false})
  const [firstName, setFirstName] = useState({value: '', valid: false})
  const [lastName, setLastName] = useState({value: '', valid: false})
  const [logo, setLogo] = useState({value: '', file: null as any, valid: true})
  const [email, setEmail] = useState({value: '', valid: false})
  const [phone, setPhone] = useState({value: '', valid: false})
  const [website, setWebsite] = useState({value: '', valid: true})
  const [vatNumber, setVatNumber] = useState({value: '', valid: true})
  const [registerNumber, setRegisterNumber] = useState({value: '', valid: true})
  const [street, setStreet] = useState({value: '', valid: false})
  const [streetNumber, setStreetNumber] = useState({value: '', valid: false})
  const [additional, setAdditional] = useState({value: '', valid: true})
  const [administrativeArea, setAdministrativeArea] = useState({value: '', valid: false})
  const [city, setCity] = useState({value: '', valid: false})
  const [zipCode, setZipCode] = useState({value: '', valid: false})
  const [country, setCountry] = useState({value: '', valid: false})
  const [currency, setCurrency] = useState({value: '', object: null as any, valid: false})
  const [financialEntity, setFinancialEntity] = useState({value: '', valid: true})
  const [iban, setIban] = useState({value: '', valid: true})
  const [bban, setBban] = useState({value: '', valid: true})
  const [bic, setBic] = useState({value: '', valid: true})
  const [language, setLanguage] = useState({value: '', valid: false})
  const [qrType, setQrTtype] = useState({value: '', valid: false})
  const [companies, setCompanies] = useState<string[]>([])

  const submitContactCreate = async () => {
    setLoadIndicator('on')

    if (companyForUser) {
      let addressStreetNumber: string = (streetNumber.value === 'NN' || streetNumber.value === 'nn') ? streetNumber.value.toUpperCase() : streetNumber.value
      let valueIban: string = iban.value.toUpperCase().trim().replaceAll(' ', '')
      let valueBban: string = bban.value.toUpperCase().trim().replaceAll(' ', '')
      let valueBic: string = bic.value.toUpperCase().trim().replaceAll(' ', '')

      await ContactDA.postContact(companyForUser.id, type.value, uuid.value, name.value, firstName.value, lastName.value, logo.file, email.value, phone.value, website.value, vatNumber.value, registerNumber.value, street.value, addressStreetNumber, additional.value, administrativeArea.value, city.value, zipCode.value, country.value, currency.object, financialEntity.value, valueIban, valueBban, valueBic, language.value, qrType.value, companies).then( (response) => {
        if (response.status === 201) {
          contactResponse = response.data

          if (contactResponse.companies.includes(companyForUser.id)) {
            loadContactsForCompany(companyForUser.id)
          }

          Swal.fire({
            title: lang.labels.successfullyCreatedContact,
            text: lang.labels.actionCompletedReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            navigate('/app/contact/list', {replace: true})
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  }

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.contacts, branch: lang.labels.createContact}, company: false})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { userLoggedIn && companyForUser
        ?
        <div className="card">
          <div className="card-body">
            <AppContactCreateStepper component={"page"} loadIndicator={loadIndicator} uuid={uuid} type={type} name={name} firstName={firstName} lastName={lastName} logo={logo} email={email} phone={phone} website={website} vatNumber={vatNumber} registerNumber={registerNumber} street={street} streetNumber={streetNumber} additional={additional} administrativeArea={administrativeArea} city={city} zipCode={zipCode} country={country} currency={currency} financialEntity={financialEntity} iban={iban} bban={bban} bic={bic} language={language} qrType={qrType} companies={companies} setUuid={setUuid} setType={setType} setName={setName} setFirstName={setFirstName} setLastName={setLastName} setLogo={setLogo} setEmail={setEmail} setPhone={setPhone} setWebsite={setWebsite} setVatNumber={setVatNumber} setRegisterNumber={setRegisterNumber} setStreet={setStreet} setStreetNumber={setStreetNumber} setAdditional={setAdditional} setAdministrativeArea={setAdministrativeArea} setCity={setCity} setZipCode={setZipCode} setCountry={setCountry} setCurrency={setCurrency} setFinancialEntity={setFinancialEntity} setIban={setIban} setBban={setBban} setBic={setBic} setLanguage={setLanguage} setQrTtype={setQrTtype} setCompanies={setCompanies} submitContactCreate={submitContactCreate}></AppContactCreateStepper>
          </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </div>
  )
}

export default ContactCreatePage;
