import CurrencyList from 'currency-list'
import CurrencyCodes from 'currency-codes'
import { Currency } from '../models/currency.model'

export function currencyList(): Currency[] {
  let codes: string[] = ['CHF', 'EUR', 'USD', 'PEN']
  let currencies: Currency[] = []
  let currenciesAll: Currency[] = []

  for (let code of codes) {
    if (CurrencyList.get(code)) {
      currencies.push({code: CurrencyList.get(code).code, name: CurrencyList.get(code).name, symbol: CurrencyList.get(code).symbol})
    }
  }

  for (let code of CurrencyCodes.codes()) {
    if (CurrencyList.get(code)) {
      currenciesAll.push({code: CurrencyList.get(code).code, name: CurrencyList.get(code).name, symbol: CurrencyList.get(code).symbol})
    }
  }

  return currencies
}

export function currencyValue(code: string): string {
  if (code.length > 0 && currencyList().find((item: Currency) => (item.code === code))) {
    let currency: Currency = currencyList().find((item: Currency) => (item.code === code)) as Currency
    return `${currency.code}: ${currency.name} (${currency.symbol})`
  } else {
    return code
  }
}