import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { TreasuryAccount } from '../models/treasury.account.model';
import { Company } from '../models/company.model';
import { Error } from '../models/error.model';
import { TreasuryAccountHolder } from '../models/treasury.account.holder.model';
import { CompanyTreasuryAccounts } from '../models/company.treasury.accounts.model';
import { appToken } from '../scripts/app.token.script';

export class TreasuryAccountDA {
  static apiResponse: ApiResponse

  public static async getTreasuryAccounts(id_company: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let treasuryAccountsByCompany: CompanyTreasuryAccounts | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/treasury.accounts.data.json').then(response => { treasuryAccountsByCompany = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (treasuryAccountsByCompany!.id_company === id_company) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: treasuryAccountsByCompany
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/bank_accounts`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async postTreasuryAccount(id_company: string, type: string, name: string, iban: string, bban: string, bic: string, membership: string, qr_iban: string, reference: string, is_reference: boolean, default_treasury_account: boolean, account_holder: TreasuryAccountHolder): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let company: Company | null = null
      let treasuryAccounts: TreasuryAccount[] | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/company.data.json').then(response => { company = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/treasury.accounts.data.json').then(response => { treasuryAccounts = response.data.treasury_accounts }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (company!.id === id_company) {
        if (default_treasury_account) {
          treasuryAccounts = treasuryAccounts!.map((item: TreasuryAccount) => {
            item.default = false
            return item
          })
        }

        treasuryAccounts!.push({
          id: '',
          type: type,
          name: name,
          iban: iban,
          bban: bban,
          bic: bic,
          membership: membership,
          qr_iban: qr_iban,
          reference: reference,
          is_reference: is_reference,
          default: default_treasury_account,
          account_holder: account_holder
        })

        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: treasuryAccounts
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/bank_accounts`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        type,
        name,
        iban,
        bban,
        bic,
        membership,
        qr_iban,
        account_holder,
        reference,
        is_reference,
        default_value: default_treasury_account
      }

      await axios.post(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putTreasuryAccount(id_company: string, id_treasury_account: string, name: string, iban: string, bban: string, bic: string, membership: string, qr_iban: string, reference: string, is_reference: boolean, account_holder: TreasuryAccountHolder): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let treasuryAccounts: TreasuryAccount[] | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/treasury.accounts.data.json').then(response => { treasuryAccounts = response.data.treasury_accounts }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (treasuryAccounts!.some((item: TreasuryAccount) => (item.id === id_treasury_account))) {
        treasuryAccounts!.find((item: TreasuryAccount) => (item.id === id_treasury_account))!.name = name
        treasuryAccounts!.find((item: TreasuryAccount) => (item.id === id_treasury_account))!.iban = iban
        treasuryAccounts!.find((item: TreasuryAccount) => (item.id === id_treasury_account))!.bban = bban
        treasuryAccounts!.find((item: TreasuryAccount) => (item.id === id_treasury_account))!.bic = bic
        treasuryAccounts!.find((item: TreasuryAccount) => (item.id === id_treasury_account))!.membership = membership
        treasuryAccounts!.find((item: TreasuryAccount) => (item.id === id_treasury_account))!.qr_iban = qr_iban
        treasuryAccounts!.find((item: TreasuryAccount) => (item.id === id_treasury_account))!.reference = reference
        treasuryAccounts!.find((item: TreasuryAccount) => (item.id === id_treasury_account))!.is_reference = is_reference
        treasuryAccounts!.find((item: TreasuryAccount) => (item.id === id_treasury_account))!.account_holder = account_holder

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: treasuryAccounts
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/bank_accounts/${id_treasury_account}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        name,
        iban,
        bban,
        bic,
        membership,
        reference,
        is_reference,
        account_holder,
        qr_iban
      }

      await axios.put(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putTreasuryAccountDefault(id_company: string, id_treasury_account: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let treasuryAccounts: TreasuryAccount[] | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/treasury.accounts.data.json').then(response => { treasuryAccounts = response.data.treasury_accounts }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (treasuryAccounts!.some((item: TreasuryAccount) => (item.id === id_treasury_account))) {
        treasuryAccounts = treasuryAccounts!.map((item: TreasuryAccount) => {
          if (item.id === id_treasury_account) {
            item.default = true
          } else {
            item.default = false
          }
          return item
        })

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: treasuryAccounts
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/bank_accounts/${id_treasury_account}/default`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.put(url, null, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async deleteTreasuryAccount(id_company: string, id_treasury_account: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let treasuryAccounts: TreasuryAccount[] | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/treasury.accounts.data.json').then(response => { treasuryAccounts = response.data.treasury_accounts }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (treasuryAccounts!.some((item: TreasuryAccount) => (item.id === id_treasury_account))) {
        treasuryAccounts = treasuryAccounts!.filter((item: TreasuryAccount) => (item.id !== id_treasury_account))

        if (treasuryAccounts!.length > 0 && treasuryAccounts!.filter((item: TreasuryAccount) => (item.default === true)).length === 0) {
          treasuryAccounts[0].default = true
        }

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: treasuryAccounts
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/bank_accounts/${id_treasury_account}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.delete(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }
}
