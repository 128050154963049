import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Workbook  } from 'exceljs';
import FileSaver from 'file-saver';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import axios from 'axios';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { Company } from '../../models/company.model';
import { Quote } from '../../models/quote.model';
import { QuoteGroup } from '../../models/quote.group.model';
import { Customer } from '../../models/customer.model';
import { modalHide } from '../../tools/modal.tool';
import { legendInvalid, legendValidInvalidRestart, legendValid } from '../../tools/legend.data.entry.tool';
import { dateFormatAbbreviated } from '../../scripts/datetime.script';
import { accumulatedAmountLite } from '../../scripts/amount.script';
import { nameGroup } from '../../libraries/group.name.library';

import AppLegend from '../element/Legend';

import logo from '../../assets/images/svg/logo-official-dark.png';

export interface AppModalQuoteExportProps {
  companyForUser: Company | undefined | null,
  grouped: {state: boolean, desc: string},
  quotes: Quote[] | undefined | null,
  quoteGroups: QuoteGroup[] | undefined | null
};

const AppModalQuoteExport: React.FunctionComponent<AppModalQuoteExportProps> = ({companyForUser, grouped, quotes, quoteGroups}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [formatToExport, setFormatToExport] = useState({value: '', valid: false})

  const fileExcel = async (company: Company, tableTitle: string[][], tableData: any[][]) => {
    let workbook = new Workbook()
    let worksheet = workbook.addWorksheet('Quotes Data')

    worksheet.addRow([])
    worksheet.mergeCells(`A1:K1`)
    worksheet.getRow(1).height = 35

    let imageBuffer = await axios.get(logo, {responseType: 'arraybuffer'})

    let imageLogotipo = workbook.addImage({
      buffer: imageBuffer.data,
      extension: 'png'
    })

    worksheet.addImage(imageLogotipo, { tl: {col: 0.1, row: 0.2}, ext: {width: 100, height: 30}})

    let titleRow = worksheet.addRow([`${company.name} company list of quotes`])
    titleRow.font = {size: 18, bold: true}
    titleRow.getCell(1).alignment = {vertical:'distributed', horizontal: 'center'}

    worksheet.mergeCells(`A${titleRow.number}:K${titleRow.number}`)
    worksheet.getRow(2).height = 30

    worksheet.addRow([])
    worksheet.mergeCells(`A3:K3`)
    worksheet.getRow(3).height = 10

    let headerRow = worksheet.addRow(tableTitle[0])
    headerRow.font = {bold: true, color: {argb: 'FFFFFF'}}
    headerRow.eachCell( (cell) => {
      cell.alignment = {vertical:'distributed', horizontal: 'left'}
      cell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: '2980BA'}, bgColor: {argb: '2980BA'}}
    })

    worksheet.getRow(4).height = 20

    tableData.forEach((item) => { 
      let bodyRow = worksheet.addRow(item) 

      if (grouped.state) {
        if (item.length === 1) {
          bodyRow.font = {bold: true}
        }
      }
    })

    if (grouped.state) {
      for (let i = 0; i < tableData.length; i++) {
        if (tableData[i].length === 1) {
          worksheet.mergeCells(`A${i + 5}:K${i + 5}`)
        }
      }
    }

    worksheet.getColumn(1).width = 18;
    worksheet.getColumn(2).width = 35;
    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 13;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 13;
    worksheet.getColumn(8).width = 18;
    worksheet.getColumn(9).width = 18;
    worksheet.getColumn(10).width = 18;
    worksheet.getColumn(11).width = 18;

    workbook.xlsx.writeBuffer().then( data => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      FileSaver.saveAs(blob, `Quotes_of_${company.name.replaceAll(' ', '_')}.xlsx`);
    })
  }

  const filePdf = async (company: Company, tableTitle: string[][], tableData: any[][]) => {
    let document = new jsPDF('landscape', 'mm', [340, 220])

    if (grouped.state) {
      for (let i = 0; i < tableData.length; i++) {
        if (tableData[i].length === 1) {
          tableData[i][0] = {colSpan: 11, content: tableData[i][0], styles: {fontStyle: 'bold'}}
        } else {
          tableData[i][7] = {content: tableData[i][7], styles: {halign: 'right'}}
          tableData[i][8] = {content: tableData[i][8], styles: {halign: 'right'}}
          tableData[i][9] = {content: tableData[i][9], styles: {halign: 'right'}}
          tableData[i][10] = {content: tableData[i][10], styles: {halign: 'right'}}
        }
      }
    } else {
      for (let i = 0; i < tableData.length; i++) {
        tableData[i][7] = {content: tableData[i][7], styles: {halign: 'right'}}
        tableData[i][8] = {content: tableData[i][8], styles: {halign: 'right'}}
        tableData[i][9] = {content: tableData[i][9], styles: {halign: 'right'}}
        tableData[i][10] = {content: tableData[i][10], styles: {halign: 'right'}}
      }
    }

    document.addImage(logo, 'PNG', 290, 5, 30, 9)

    document.setFontSize(18)
    document.setFont('helvetica', 'bold')
    document.text(`${company.name} - ${lang.labels.companyQuoteList}`, 25, 20)
    autoTable(document, {head: tableTitle, body: tableData, startY: 25})

    document.save(`Quotes_of_${company.name.replaceAll(' ', '_')}.pdf`)
  }

  const handleChangeFormat = (item: string) => {
    setFormatToExport({...formatToExport, value: item, valid: true})
    legendValid('modal-quote-export-container-validate-format-required')
  }

  const executeQuoteExport = () => {
    setLoadIndicator('on')

    if (companyForUser && quotes && quoteGroups && formatToExport.valid) {
      let tableTitle: string[][] = [[lang.labels.quoteNumber.toUpperCase(), lang.labels.concerning.toUpperCase(), lang.labels.customer.toUpperCase(), lang.labels.status.toUpperCase(), lang.labels.createdDate.toUpperCase(), lang.labels.validUntil.toUpperCase(), lang.labels.currency.toUpperCase(), lang.labels.subTotal.toUpperCase(), lang.labels.rebateTotal.toUpperCase(), lang.labels.taxTotal.toUpperCase(), lang.labels.amountTotal.toUpperCase()]]
      let tableData: any[][]  = [[]]

      tableData.pop()

      if (grouped.state) {
        for (let i = 0; i < quoteGroups.length; i++) {
          tableData.push([`GROUP ${i + 1}: ${nameGroup(lang, grouped.desc, quoteGroups[i].group_detail).toUpperCase()}`])
          for (let quote of quoteGroups[i].quotes) {
            tableData.push([quote.quote_number, quote.concerning, nameCustomer(quote.customer), quote.status.replace(quote.status.charAt(0), quote.status.charAt(0).toUpperCase()), dateFormatAbbreviated(quote.created_date), dateFormatAbbreviated(quote.valid_until), `${quote.currency.name} (${quote.currency.symbol})`, (Number(quote.amount.sub_total.toFixed(2))), Number(quote.amount.rebate_total.toFixed(2)), Number(quote.amount.tax_total.toFixed(2)), Number(quote.amount.total.toFixed(2))])
          }
          tableData.push(['', '', '', '', '', '', '', Number(accumulatedAmountLite(quoteGroups[i].quotes).subtotal.toFixed(2)), Number(accumulatedAmountLite(quoteGroups[i].quotes).rebate.toFixed(2)), Number(accumulatedAmountLite(quoteGroups[i].quotes).tax.toFixed(2)), Number(accumulatedAmountLite(quoteGroups[i].quotes).total.toFixed(2))])
        }
      } else {
        for (let quote of quotes) {
          tableData.push([quote.quote_number, quote.concerning, nameCustomer(quote.customer), quote.status.replace(quote.status.charAt(0), quote.status.charAt(0).toUpperCase()), dateFormatAbbreviated(quote.created_date), dateFormatAbbreviated(quote.valid_until), `${quote.currency.name} (${quote.currency.symbol})`, Number(quote.amount.sub_total.toFixed(2)), Number(quote.amount.rebate_total.toFixed(2)), Number(quote.amount.tax_total.toFixed(2)), Number(quote.amount.total.toFixed(2))])
        }
        tableData.push(['', '', '', '', '', '', '', Number(accumulatedAmountLite(quotes).subtotal.toFixed(2)), Number(accumulatedAmountLite(quotes).rebate.toFixed(2)), Number(accumulatedAmountLite(quotes).tax.toFixed(2)), Number(accumulatedAmountLite(quotes).total.toFixed(2))])
      }

      setTimeout( () => {
        switch (formatToExport.value) {
          case 'excel':
            fileExcel(companyForUser, tableTitle, tableData)
            break;
          case  'pdf':
            filePdf(companyForUser, tableTitle, tableData)
            break;
        }

        setLoadIndicator('off')
        executeHideModalQuoteExport()
      }, 1000)
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        legendInvalid('modal-quote-export-container-validate-format-required')
        setLoadIndicator('off')
      })
    }
  }

  const executeHideModalQuoteExport = () => {
    modalHide('modal-quote-export')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function nameCustomer(customer: Customer): string {
    return (customer.type === 'person') ? `${customer.data.first_name} ${customer.data.last_name}` : customer.data.name
  }

  function restartModal() {
    legendValidInvalidRestart('modal-quote-export-container-validate-format-required')
    setFormatToExport({value: '', valid: false})
  }

  return (
    <div id="modal-quote-export" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-500px">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.exportQuotes}</h2>
          </div>
          <div className="modal-body">
            <div className="form">
              <div className="fv-row">
                <label className="form-label required">{lang.labels.selectExportFormat}:</label>
                <Dropdown>
                  <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                    <span className="selection">
                      <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                        <span className="select2-selection__rendered" role="textbox">
                          <span className="select2-selection__placeholder">
                            { formatToExport.valid
                              ?
                              <>
                                {formatToExport.value === "excel" && "Excel (.xlsx)"}
                                {formatToExport.value === "pdf" && "PDF (.pdf)"}
                              </>
                              :
                              <>
                                {lang.labels.selectOption}
                              </>
                            }
                          </span>
                        </span>
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                    <span className="select2-dropdown select2-dropdown--below">
                      <span className="select2-results">
                        <ul className="select2-results__options" role="listbox">
                          <li className={`select2-results__option select2-results__option--selectable ${formatToExport.value === "excel" && "select2-results__option--selected"}`} role="option" aria-selected={formatToExport.value === "excel"}>
                            <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeFormat("excel")}>{lang.labels.excel} (.xlsx)</Dropdown.Item>
                          </li>
                          <li className={`select2-results__option select2-results__option--selectable ${formatToExport.value === "pdf" && "select2-results__option--selected"}`} role="option" aria-selected={formatToExport.value === "pdf"}>
                            <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeFormat("pdf")}>{lang.labels.pdf} (.pdf)</Dropdown.Item>
                          </li>
                        </ul>
                      </span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
                <AppLegend component={"modal-quote-export"} attribute={{validity: formatToExport.valid, name: "format", index: null, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            </div>
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalQuoteExport}>{lang.labels.discard}</button>
            <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={executeQuoteExport}>
              <span className="indicator-label">{lang.labels.export}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppModalQuoteExport;
