import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { CompanyCategories } from '../models/company.categories.model';
import { Company } from '../models/company.model';
import { Category } from '../models/category.model';
import { Service } from '../models/service.model';
import { Product } from '../models/product.model';
import { appToken } from '../scripts/app.token.script';

export class CategoryDA {
  static apiResponse: ApiResponse

  public static async getCategories(id_company: string, category_name: string, sort_field: string, sort_mode: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let categoriesByCompany: CompanyCategories | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/categories.data.json').then(response => { categoriesByCompany = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (categoriesByCompany!.id_company === id_company) {
        let listCategories: Category[] = []

        if (category_name.length !== 0) {
          for (let category of categoriesByCompany!.categories) {
            if (category.display_name.toLowerCase().indexOf(category_name.toLocaleLowerCase()) !== -1) {
              listCategories.push(category)
            }
          }
        } else {
          listCategories = categoriesByCompany!.categories
        }

        categoriesByCompany!.filter.category_name = category_name
        categoriesByCompany!.filter.sort_field = sort_field
        categoriesByCompany!.filter.sort_mode = sort_mode
        categoriesByCompany!.categories = listCategories

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: categoriesByCompany
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/categories?name=${category_name}&&order_by=${sort_field}&order_direction=${sort_mode}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async getCategory(id_company: string, id_category: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let category: Category | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/category.data.json').then(response => { category = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (category!.id === id_category) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: category
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/categories/${id_category}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async postCategory(id_company: string, display_name: string, description: string, names: { en: string, fr: string, it: string, de: string }): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let company: Company | null = null
      let category: Category | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/company.data.json').then(response => { company = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (company!.id === id_company) {
        category = {
          id: `${(Math.floor(Math.random() * 1000)).toString()}category${(Math.floor(Math.random() * 1000)).toString()}`,
          display_name: display_name,
          description: description,
          names: names,
          services: [],
          products: []
        }

        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: category
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/categories`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        display_name,
        description,
        names
      }

      await axios.post(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putCategory(id_company: string, id_category: string, display_name: string, description: string, names: { en: string, fr: string, it: string, de: string }): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let category: Category | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/category.data.json').then(response => { category = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (category!.id === id_category) {
        category!.display_name = display_name
        category!.description = description
        category!.names = names

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: category
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/categories/${id_category}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        display_name: display_name,
        description: description,
        names: names
      }

      await axios.put(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putCategoryProducts(id_company: string, id_category: string, id_services: string[], id_products: string[]): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let category: Category | null = null
      let services: Service[] | null = null
      let products: Product[] | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/category.data.json').then(response => { category = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/services.data.json').then(response => { services = response.data.services }).catch(error => { alert(error) })
      await axios.get('../../../../../database/products.data.json').then(response => { products = response.data.products }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (category!.id === id_category) {
        category!.services = services!.filter((item: Service) => id_services.includes(item.id))
        category!.products = products!.filter((item: Product) => id_products.includes(item.id))

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: category
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/categories/${id_category}/listing`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        services_ids: id_services,
        products_ids: id_products
      }

      await axios.put(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async deleteCategories(id_company: string, id_categories: string[]): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let categories: Category[] | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/categories.data.json').then(response => { categories = response.data.categories }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (categories!.some((item: Category) => id_categories.includes(item.id))) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/categories`

      let config: any = {
        headers: { Authorization: appToken() },
        data: { categories: id_categories }
      }

      await axios.delete(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }
}
