import flagUnitedStates from '../assets/images/icons/flag-united-states-icon.svg';
import flagFrance from '../assets/images/icons/flag-france-icon.svg';
import flagSpain from '../assets/images/icons/flag-spain-icon.svg';
import logoDefaultUser from '../assets/images/avatar.png';
import logoDefaultCompany from '../assets/images/company.png';
import imageDefaultProduct from '../assets/images/product.png';
import imageDefaultService from '../assets/images/service.png';

export function imageLanguage(language: string): string {
  switch (language) {
    case 'english':
      return flagUnitedStates
    case 'french':
      return flagFrance
    case 'spanish':
      return flagSpain
    default:
      return flagUnitedStates
  }
}

export function imageUser(avatar: string): string {
  return (avatar.length === 0) ? logoDefaultUser : avatar
}

export function imageCompany(logo: string): string {
  return (logo.length === 0) ? logoDefaultCompany : logo
}

export function imageService(photo: string): string {
  return (photo.length === 0) ? imageDefaultService : photo
}

export function imageProduct(photo: string): string {
  return (photo.length === 0) ? imageDefaultProduct : photo
}
