import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { Company } from '../models/company.model';
import { Order } from '../models/order.model';
import { Currency } from '../models/currency.model';
import { Address } from '../models/address.model';
import { CompanyOrders } from '../models/company.orders.model';
import { appToken } from '../scripts/app.token.script';

export class OrderDA {
  static apiResponse: ApiResponse

  public static async getOrders(id_company: string, interval: string, start_date: string, end_date: string, contact_id: string, treasury_id: string, status: string, expiration: string, sort_field: string, sort_mode: string, group_by: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let ordersByCompany: CompanyOrders | null = null
      let orderGroupsByCompany: CompanyOrders | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/sale.orders.list.data.json').then(response => { ordersByCompany = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/sale.orders.group.data.json').then(response => { orderGroupsByCompany = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (ordersByCompany!.id_company === id_company || orderGroupsByCompany!.id_company === id_company) {
        if (group_by.length === 0) {
          ordersByCompany!.filter.interval = interval
          ordersByCompany!.filter.start_date = start_date
          ordersByCompany!.filter.end_date = end_date
          ordersByCompany!.filter.treasury_id = treasury_id
          ordersByCompany!.filter.contact_id = contact_id
          ordersByCompany!.filter.status = status
          ordersByCompany!.filter.expiration = expiration
          ordersByCompany!.filter.sort_field = sort_field
          ordersByCompany!.filter.sort_mode = sort_mode
          ordersByCompany!.filter.group_by = group_by
        } else {
          orderGroupsByCompany!.filter.interval = interval
          orderGroupsByCompany!.filter.start_date = start_date
          orderGroupsByCompany!.filter.end_date = end_date
          orderGroupsByCompany!.filter.contact_id = contact_id
          orderGroupsByCompany!.filter.treasury_id = treasury_id
          orderGroupsByCompany!.filter.status = status
          orderGroupsByCompany!.filter.expiration = expiration
          orderGroupsByCompany!.filter.sort_field = sort_field
          orderGroupsByCompany!.filter.sort_mode = sort_mode
          orderGroupsByCompany!.filter.group_by = group_by
        }

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: (group_by.length === 0) ? ordersByCompany : orderGroupsByCompany
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let startOfDay: Date = new Date(new Date().setHours(0, 0, 0, 0))
      let endOfDay: Date = new Date(new Date().setHours(23, 59, 59, 999))
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/orders?interval=${interval}&start_date=${start_date}&end_date=${end_date}&id_contact=${contact_id}&id_bank_account=${treasury_id}&status=${status}&expiration=${expiration}&sort_field=${sort_field}&sort_mode=${sort_mode}&group_by=${group_by}&start_day=${startOfDay}&end_day=${endOfDay}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async getOrder(id_company: string, id_order: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let order: Order | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/sale.order.data.json').then(response => { order = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (order!.id === id_order) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: order
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/orders/${id_order}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async postOrder(id_company: string, id_customer: string, concerning: string, date: string, valid_until: string, currency: Currency, treasury_id: string, language: string, street: string, street_number: string, additional: string, administrative_area: string, city: string, zip_code: string, country: string, requirements: { type: string, description: string, contents: { detail: string, quantity: string, price_unit: string, tax_rate: string, tax_included: boolean, unit: string }[] }[], signatures: string[], company_data: { uuid: string, name: string, logo: string, address: Address }, reference: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let company: Company | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/company.data.json').then(response => { company = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (company!.id === id_company) {
        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/orders`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        concerning: concerning,
        signatures: signatures,
        company: company_data,
        currency: currency,
        valid_until: valid_until,
        delivery_address: {
          street: street,
          number: street_number,
          additional: additional,
          administrative_area: administrative_area,
          city: city,
          zip_code: zip_code,
          country: country
        },
        customer: id_customer,
        requirements: requirements,
        treasury_account: treasury_id,
        language: language,
        reference: reference,
        created_date: date
      }

      await axios.post(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async postOrderReference(id_company: string, id_quote: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let order: Order | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/sale.order.data.json').then(response => { order = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (order!.quote.id === id_quote) {
        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: order
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/orders/${id_quote}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.post(url, null, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putOrder(id_company: string, id_order: string, concerning: string, date: string, valid_until: string, currency: Currency, treasury_id: string, language: string, street: string, street_number: string, additional: string, administrative_area: string, city: string, zip_code: string, country: string, requirements: { type: string, description: string, contents: { detail: string, quantity: string, price_unit: string, tax_rate: string, tax_included: boolean, unit: string }[] }[], signatures: string[]): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let order: Order | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/sale.order.data.json').then(response => { order = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (order!.id === id_order) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/orders/${id_order}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        concerning: concerning,
        signatures: signatures,
        currency: currency,
        valid_until: valid_until,
        requirements: requirements,
        treasury_account: treasury_id,
        language: language,
        created_date: date,
        delivery_address: {
          street: street,
          number: street_number,
          additional: additional,
          administrative_area: administrative_area,
          city: city,
          zip_code: zip_code,
          country: country
        }
      }

      await axios.put(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putOrderStatus(id_company: string, id_order: string, new_status: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let order: Order | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/sale.order.data.json').then(response => { order = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (order!.id === id_order) {
        order!.status = new_status

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: order
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/orders/${id_order}/status`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = {
        status: new_status
      }

      await axios.put(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async sendOrder(id_company: string, id_order: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let order: Order | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/sale.order.data.json').then(response => { order = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (order!.id === id_order) {
        order!.status = 'pending'

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: order
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/orders/${id_order}/send`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.post(url, null, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }
}
