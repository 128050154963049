import React, { Dispatch, SetStateAction, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { CategoryDA } from '../../services/category.service';
import { Error } from '../../models/error.model';
import { Company } from '../../models/company.model';
import { Category } from '../../models/category.model';
import { Service } from '../../models/service.model';
import { Product } from '../../models/product.model';
import { imageProduct } from '../../scripts/image.value.script';
import { modalHide } from '../../tools/modal.tool';

import imageSearch from '../../assets/images/themes/image-search.png';
import imgServiceDefault from '../../assets/images/service.png';
import imgProductDefault from '../../assets/images/product.png';

export interface AppModalCategoryUpdateListingProps {
  companyForUser: Company | undefined | null,
  servicesForCompany: Service[] | undefined | null,
  productsForCompany: Product[] | undefined | null,
  category: Category | undefined | null,
  categoryServices: string[] | undefined | null,
  categoryProducts: string[] | undefined | null,
  setCategory: Dispatch<SetStateAction<Category | undefined | null>>,
  setCategoryServices: Dispatch<SetStateAction<string[] | undefined | null>>,
  setCategoryProducts: Dispatch<SetStateAction<string[] | undefined | null>>,
  loadServicesForCompany: Function,
  loadProductsForCompany: Function
};

let errorResponse: Error, categoryResponse: Category;

const AppModalCategoryUpdateListing: React.FunctionComponent<AppModalCategoryUpdateListingProps> = ({companyForUser, servicesForCompany, productsForCompany, category, categoryServices, categoryProducts, setCategory, setCategoryServices, setCategoryProducts, loadServicesForCompany, loadProductsForCompany}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')

  const submitCategoryUpdate = async () => {
    setLoadIndicator('on')

    if (companyForUser && category && categoryServices && categoryProducts) {
      await CategoryDA.putCategoryProducts(companyForUser.id, category.id, categoryServices, categoryProducts).then( (response) => {
        if (response.status === 200) {
          categoryResponse = response.data
          setCategory(categoryResponse)

          loadProductsForCompany(companyForUser.id)
          loadServicesForCompany(companyForUser.id)

          Swal.fire({
            title: lang.labels.successfullyUpdatedCategory,
            text: lang.labels.actionCompletedReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalCategoryUpdateListing()
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: `${errorResponse.message}!`,
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeSelectItem = (item: string, event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    if (categoryServices && item === 'service') {
      let list: string[] = (event.target.checked) ? (!categoryServices.includes(event.target.value)) ? categoryServices.concat(event.target.value) : categoryServices : categoryServices.filter((other_item) => (other_item !== event.target.value))
      setCategoryServices(list)
    }
    if (categoryProducts && item === 'product') {
      let list: string[] = (event.target.checked) ? (!categoryProducts.includes(event.target.value)) ? categoryProducts.concat(event.target.value) : categoryProducts : categoryProducts.filter((other_item) => (other_item !== event.target.value))
      setCategoryProducts(list)
    }
  }

  const executeHideModalCategoryUpdateListing = () => {
    modalHide('modal-category-update-listing')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function restartModal() {
    setCategoryServices([])
    setCategoryProducts([])
  }

  return (
    <div id="modal-category-update-listing" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-600px">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.updateCategoryItemList}</h2>
          </div>
          <div className="modal-body">
            { productsForCompany && servicesForCompany && category && categoryServices && categoryProducts
              ?
              <>
                <div className="text-justify text-gray-700 mb-5">
                  {lang.labels.checkOrUncheck}
                  { ((servicesForCompany.length === 0 && productsForCompany.length === 0) || (servicesForCompany.length > 0 && productsForCompany.length > 0)) &&
                    <span className="text-lowercase fw-bold mx-1">{lang.labels.services} {lang.labels.and} {lang.labels.products}</span>
                  }
                  { servicesForCompany.length > 0 && productsForCompany.length === 0 &&
                    <span className="text-lowercase fw-bold mx-1">{lang.labels.services}</span>
                  }
                  { servicesForCompany.length === 0 && productsForCompany.length > 0 &&
                    <span className="text-lowercase fw-bold mx-1">{lang.labels.products}</span>
                  }
                  {lang.labels.toAddOrRemoveCategory}
                  <strong className="ms-1">{category.display_name}</strong>
                  .
                </div>
                { servicesForCompany.length === 0 && productsForCompany.length === 0
                  ?
                  <div className="d-flex flex-column flex-center">
                    <img src={imageSearch} className="mw-125px" alt={lang.labels.none} />
                    <div className="fs-4 fw-bolder mt-1 mb-3">{lang.labels.noRecordsFound}</div>
                    <div className="fs-7">{lang.labels.startAddingNewElementsToThisCategory}</div>
                  </div>
                  :
                  <div className={`row row-cols-1 ${servicesForCompany.length > 0 && productsForCompany.length > 0 && "row-cols-md-2"}`}>
                    <div>
                      { servicesForCompany.length > 0 && productsForCompany.length > 0 &&
                        <>
                          <div className="fw-bold">{lang.labels.servicesList}</div>
                          <div className="separator separator-dashed mb-2"></div>
                        </>
                      }
                      <div className="mh-500px hover-scroll-y">
                        { servicesForCompany.map (( (item, index) => { return (
                          <div key={index}>
                            <label className="d-flex flex-stack cursor-pointer mb-2">
                              <span className="d-flex align-items-center">
                                { item.photos.length > 0
                                  ?
                                  <Carousel slide={false} controls={false} indicators={false} touch={false}>
                                    { item.photos.map (( (item, index) => { return (
                                      <Carousel.Item key={index}>
                                        <img src={imageProduct(item)} className="d-block w-35px h-35px" alt={lang.labels.photography + index} />
                                      </Carousel.Item>
                                    )}))}
                                  </Carousel>
                                  :
                                  <span className="symbol symbol-35px">
                                    <span className="symbol-label">
                                      <img src={imgServiceDefault} alt={lang.labels.photography} className="w-100 h-100" />
                                    </span>
                                  </span>
                                }
                                <span className="fs-7 ms-2 me-3">{item.display_name}</span>
                              </span>
                              <span className="form-check form-check-custom form-check-solid me-2">
                                <input className="form-check-input" type="checkbox" value={item.id} checked={categoryServices.includes(item.id)} onChange={(event) => handleChangeSelectItem("service", event)} />
                              </span>
                            </label>
                          </div>
                        )}))}
                      </div>
                    </div>
                    <div>
                      { productsForCompany.length > 0 && servicesForCompany.length > 0 &&
                        <>
                          <div className="fw-bold">{lang.labels.productsList}</div>
                          <div className="separator separator-dashed mb-2"></div>
                        </>
                      }
                      <div className="mh-500px hover-scroll-y">
                        { productsForCompany.map (( (item, index) => { return (
                          <div key={index}>
                            <label className="d-flex flex-stack cursor-pointer mb-2">
                              <span className="d-flex align-items-center">
                                { item.photos.length > 0
                                  ?
                                  <Carousel slide={false} controls={false} indicators={false} touch={false}>
                                    { item.photos.map (( (item, index) => { return (
                                      <Carousel.Item key={index}>
                                        <img src={imageProduct(item)} className="d-block w-35px h-35px" alt={lang.labels.photography + index} />
                                      </Carousel.Item>
                                    )}))}
                                  </Carousel>
                                  :
                                  <span className="symbol symbol-35px">
                                    <span className="symbol-label">
                                      <img src={imgProductDefault} alt={lang.labels.photography} className="w-100 h-100" />
                                    </span>
                                  </span>
                                }
                                <span className="fs-7 ms-2 me-3">{item.display_name}</span>
                              </span>
                              <span className="form-check form-check-custom form-check-solid me-2">
                                <input className="form-check-input" type="checkbox" value={item.id} checked={categoryProducts.includes(item.id)} onChange={(event) => handleChangeSelectItem("product", event)} />
                              </span>
                            </label>
                          </div>
                        )}))}
                      </div>
                    </div>
                  </div>
                }
              </>
              :
              <div className="d-flex justify-content-center align-items-center w-100 h-200px">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">{lang.labels.loading}</span>
                </div>
              </div>
            }
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalCategoryUpdateListing}>{lang.labels.discard}</button>
            <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={submitCategoryUpdate}>
              <span className="indicator-label">{lang.labels.update}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppModalCategoryUpdateListing;
