import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextCompany } from '../Company';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError } from '../../../../languages/translations/response';
import { SettingsDA } from '../../../../services/settings.services';
import { Error } from '../../../../models/error.model';
import { Settings } from '../../../../models/settings.model';
import { legendInvalid, legendInvalidIcon, legendInvalidEmpty, legendValidRequired, legendInvalidEmptyIcon, legendValidRequiredIcon, legendValidInvalidRestart, legendValidInvalidIconRestart, legendValid } from '../../../../tools/legend.data.entry.tool';
import { validateAttributes } from '../../../../scripts/validate.attributes.script';
import { evaluateLegendValidateRequiredIconObjectList, evaluateLegendValidateRequiredList } from '../../../../scripts/validate.legend.script';
import { expressions } from '../../../../libraries/regular.expressions.library';
import { requirementTypeValue } from '../../../../libraries/requirement.type.library';
import { deadlinetypeList, deadlinetypeValue } from '../../../../libraries/deadline.type.library';

import AppLegend from '../../../../components/element/Legend';

export interface AppCompanyUpdateParameterProps {};

let errorResponse: Error, settingsResponse: Settings;

const AppCompanyUpdateParameter: React.FunctionComponent<AppCompanyUpdateParameterProps> = props => {
  const {appServiceForUser, companyForUser, settingsForCompany, loadCompanyForUser} = UseOutletContextCompany()
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [taxes, setTaxes] = useState({value: [] as string[], valid: [] as boolean[]})
  const [deadlines, setDeadlines] = useState({value: [] as {id: string, type: string, quantity: string}[], valid: [] as {type: boolean, quantity: boolean}[]})
  const [units, setUnits] = useState({value: [] as {id: string, type: string, name: string, symbol: string}[], valid: [] as {type: boolean, name: boolean, symbol: boolean}[]})

  const submitCompanyUpdate = async () => {
    setLoadIndicator('on')

    let taxesIsValid: boolean = taxes.valid.every((item) => (item === true))
    let deadlinesIsValid: boolean = deadlines.valid.every((item) => validateAttributes(item))
    let unitsIsValid: boolean = units.valid.every((item) => validateAttributes(item))

    if (appServiceForUser && companyForUser && settingsForCompany && taxesIsValid && deadlinesIsValid && unitsIsValid) {
      await SettingsDA.putSettings(companyForUser.id, settingsForCompany.template_current, settingsForCompany.colors, taxes.value, deadlines.value, units.value).then( (response) => {
        if (response.status === 200) {
          settingsResponse = response.data

          if (settingsResponse) {
            loadCompanyForUser(appServiceForUser.api_key, companyForUser.id, () => {
              Swal.fire({
                title: lang.labels.successfullyUpdatedCompanySettings,
                text: lang.labels.actionCompletedReturningToPage,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions).then( () => {
                setLoadIndicator('off')
                restartForm()
              })
            })
          } else {
            Swal.fire({
              text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
              icon: 'error',
              showConfirmButton: false,
              timer: 1800
            } as SweetAlertOptions).then( () => {
              setLoadIndicator('off')
            })
          }
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        for (let i = 0; i < taxes.valid.length; i++) {
          if (taxes.value[i].length === 0) {
            legendInvalid('container-validate-tax-required-' + i)
          }
        }
        for (let i = 0; i < deadlines.valid.length; i++) {
          if (deadlines.value[i].type.length === 0) {
            legendInvalid('container-validate-deadline-type-required-' + i)
          }
          if (deadlines.value[i].quantity.length === 0) {
            legendInvalidIcon('input-deadline-quantity-' + i, 'container-validate-deadline-quantity-required-' + i)
          }
        }
        for (let i = 0; i < units.valid.length; i++) {
          if (units.value[i].type.length === 0) {
            legendInvalid('container-validate-unit-type-required-' + i)
          }
          if (units.value[i].name.length === 0) {
            legendInvalidIcon('input-unit-name-' + i, 'container-validate-unit-name-required-' + i)
          }
          if (units.value[i].symbol.length === 0) {
            legendInvalidIcon('input-unit-symbol-' + i, 'container-validate-unit-symbol-required-' + i)
          }
        }
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeTax = (index: number, event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let tempTaxesValue = taxes.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        return event.target.value
      } else {
        return temp_item
      }
    })

    setTaxes({...taxes, value: tempTaxesValue})
  }

  const handleChangeDeadlineType = (index: number, item: string) => {
    let tempDeadlinesValue = deadlines.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.type = item
      }
      return temp_item
    })
    let tempDeadlinesValid = deadlines.valid.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.type = true
      }
      return temp_item
    })

    setDeadlines({...deadlines, value: tempDeadlinesValue, valid: tempDeadlinesValid})
    legendValid('container-validate-deadline-type-required-' + index)
  }

  const handleChangeDeadlineQuantity = (index: number, event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let tempDeadlinesValue = deadlines.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.quantity = event.target.value
      }
      return temp_item
    })

    setDeadlines({...deadlines, value: tempDeadlinesValue})
  }

  const handleChangeUnitType = (index: number, item: string) => {
    let tempUnitsValue = units.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.type = item
      }
      return temp_item
    })
    let tempUnitsValid = units.valid.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.type = true
      }
      return temp_item
    })

    setUnits({...units, value: tempUnitsValue, valid: tempUnitsValid})
    legendValid('container-validate-unit-type-required-' + index)
  }

  const handleChangeUnitName = (index: number, event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let tempUnitsValue = units.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.name = event.target.value
      }
      return temp_item
    })

    setUnits({...units, value: tempUnitsValue})
  }

  const handleChangeUnitSymbol = (index: number, event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let tempUnitsValue = units.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        temp_item.symbol = event.target.value
      }
      return temp_item
    })

    setUnits({...units, value: tempUnitsValue})
  }

  const validateTax = (index: number) => {
    evaluateLegendValidateRequiredList(expressions.tax, index, taxes, setTaxes, 'container-validate-tax-valid-' + index, 'container-validate-tax-required-' + index)
  }

  const validateDeadlineQuantity = (index: number) => {
    evaluateLegendValidateRequiredIconObjectList(expressions.number, index, 'quantity', deadlines, setDeadlines, 'input-deadline-quantity-' + index, 'container-validate-deadline-quantity-valid-' + index, 'container-validate-deadline-quantity-required-' + index)
  }

  const validateUnitName = (index: number) => {
    evaluateLegendValidateRequiredIconObjectList(expressions.text, index, 'name', units, setUnits, 'input-unit-name-' + index, 'container-validate-unit-name-valid-' + index, 'container-validate-unit-name-required-' + index)
  }

  const validateUnitSymbol = (index: number) => {
    evaluateLegendValidateRequiredIconObjectList(expressions.text, index, 'symbol', units, setUnits, 'input-unit-symbol-' + index, 'container-validate-unit-symbol-valid-' + index, 'container-validate-unit-symbol-required-' + index)
  }

  const executeAddTax = () => {
    let allow: boolean = true

    for (let i = 0; i < taxes.value.length; i++) {
      if (!taxes.valid[i]) {
        allow = false
        legendInvalidEmpty(taxes.value[i], 'container-validate-tax-valid-' + i, 'container-validate-tax-required-' + i)
      }
    }

    if (allow) {
      let tempTaxesValue = taxes.value.concat('')
      let tempTaxesValid = taxes.valid.concat(false)
      setTaxes({value: tempTaxesValue, valid: tempTaxesValid})
    }
  }

  const executeRemoveTax = (index: number) => {
    let tempTaxesValue = taxes.value.filter((_temp_item, temp_index) => (temp_index !== index))
    let tempTaxesValid = taxes.valid.filter((_temp_item, temp_index) => (temp_index !== index))
    setTaxes({value: tempTaxesValue, valid: tempTaxesValid})

    for (let i = 0; i < tempTaxesValue.length; i++) {
      if (tempTaxesValid[i]) {
        legendValidRequired('container-validate-tax-valid-' + i, 'container-validate-tax-required-' + i)
      } else {
        legendInvalidEmpty(tempTaxesValue[i], 'container-validate-tax-valid-' + i, 'container-validate-tax-required-' + i)
      }
    }
  }

  const executeAddDeadline = () => {
    let allow: boolean = true

    for (let i = 0; i < deadlines.value.length; i++) {
      if (!deadlines.valid[i].type || !deadlines.valid[i].quantity) {
        allow = false

        if (!deadlines.valid[i].type) {
          legendInvalid('container-validate-deadline-type-required-' + i)
        }
        if (!deadlines.valid[i].quantity) {
          legendInvalidEmptyIcon(deadlines.value[i].quantity, 'input-deadline-quantity-' + i, 'container-validate-deadline-quantity-valid-' + i, 'container-validate-deadline-quantity-required-' + i)
        }
      }
    }

    if (allow) {
      let tempDeadlinesValue = deadlines.value.concat({id: '', type: '', quantity: ''})
      let tempDeadlinesValid = deadlines.valid.concat({type: false, quantity: false})
      setDeadlines({value: tempDeadlinesValue, valid: tempDeadlinesValid})
    }
  }

  const executeRemoveDeadline = (index: number) => {
    let tempDeadlinesValue = deadlines.value.filter((_temp_item, temp_index) => (temp_index !== index))
    let tempDeadlinesValid = deadlines.valid.filter((_temp_item, temp_index) => (temp_index !== index))
    setDeadlines({value: tempDeadlinesValue, valid: tempDeadlinesValid})

    for (let i = 0; i < tempDeadlinesValue.length; i++) {
      if (tempDeadlinesValid[i].type) {
        legendValid('container-validate-deadline-type-required-' + i)
      } else {
        legendInvalid('container-validate-deadline-type-required-' + i)
      }
      if (tempDeadlinesValid[i].quantity) {
        legendValidRequiredIcon('input-deadline-quantity-' + i, 'container-validate-deadline-quantity-valid-' + i, 'container-validate-deadline-quantity-required-' + i)
      } else {
        legendInvalidEmptyIcon(tempDeadlinesValue[i].quantity, 'input-deadline-quantity-' + i, 'container-validate-deadline-quantity-valid-' + i, 'container-validate-deadline-quantity-required-' + i)
      }
    }
  }

  const executeAddUnit = () => {
    let allow: boolean = true

    for (let i = 0; i < units.value.length; i++) {
      if (!units.valid[i].type || !units.valid[i].name || !units.valid[i].symbol) {
        allow = false

        if (!units.valid[i].type) { 
          legendInvalid('container-validate-unit-type-required-' + i) 
        }
        if (!units.valid[i].name) {
          legendInvalidEmptyIcon(units.value[i].name, 'input-unit-name-' + i, 'container-validate-unit-name-valid-' + i, 'container-validate-unit-name-required-' + i)
        }
        if (!units.valid[i].symbol) { 
          legendInvalidEmptyIcon(units.value[i].symbol, 'input-unit-symbol-' + i, 'container-validate-unit-symbol-valid-' + i, 'container-validate-unit-symbol-required-' + i)
        }
      }
    }

    if (allow) {
      let tempUnitsValue = units.value.concat({id: '', type: '', name: '', symbol: ''})
      let tempUnitsValid = units.valid.concat({type: false, name: false, symbol: false})
      setUnits({value: tempUnitsValue, valid: tempUnitsValid})
    }
  }

  const executeRemoveUnit = (index: number) => {
    let tempUnitsValue = units.value.filter((_temp_item, temp_index) => (temp_index !== index))
    let tempUnitsValid = units.valid.filter((_temp_item, temp_index) => (temp_index !== index))
    setUnits({value: tempUnitsValue, valid: tempUnitsValid})

    for (let i = 0; i < tempUnitsValue.length; i++) {
      if (tempUnitsValid[i].type) {
        legendValid('container-validate-unit-type-required-' + i)
      } else {
        legendInvalid('container-validate-unit-type-required-' + i)
      }
      if (tempUnitsValid[i].name) {
        legendValidRequiredIcon('input-unit-name-' + i, 'container-validate-unit-name-valid-' + i, 'container-validate-unit-name-required-' + i)
      } else {
        legendInvalidEmptyIcon(tempUnitsValue[i].name, 'input-unit-name-' + i, 'container-validate-unit-name-valid-' + i, 'container-validate-unit-name-required-' + i)
      }
      if (tempUnitsValid[i].symbol) {
        legendValidRequiredIcon('input-unit-symbol-' + i, 'container-validate-unit-symbol-valid-' + i, 'container-validate-unit-symbol-required-' + i)
      } else {
        legendInvalidEmptyIcon(tempUnitsValue[i].symbol, 'input-unit-symbol-' + i, 'container-validate-unit-symbol-valid-' + i, 'container-validate-unit-symbol-required-' + i)
      }
    }
  }

  const executeFormRestart = () => {
    uploadData()
    restartForm()
  }

  function percentSymbol(tax: string): string {
    return (Number(tax) || Number(tax) === 0) ? '%' : ''
  }

  function restartForm() {
    for (var indexTax in taxes.valid) {
      legendValidInvalidRestart('container-validate-tax-valid-' + indexTax)
      legendValidInvalidRestart('container-validate-tax-required-' + indexTax)
    }

    for (var indexDeadline in deadlines.valid) {
      legendValidInvalidRestart('container-validate-deadline-type-required-' + indexDeadline)
      legendValidInvalidIconRestart('input-deadline-quantity-' + indexDeadline, 'container-validate-deadline-quantity-valid-' + indexDeadline)
      legendValidInvalidIconRestart('input-deadline-quantity-' + indexDeadline, 'container-validate-deadline-quantity-required-' + indexDeadline)
    }

    for (var indexUnit in units.valid) {
      legendValidInvalidRestart('container-validate-unit-type-required-' + indexUnit)
      legendValidInvalidIconRestart('input-unit-name-' + indexUnit, 'container-validate-unit-name-valid-' + indexUnit)
      legendValidInvalidIconRestart('input-unit-name-' + indexUnit, 'container-validate-unit-name-required-' + indexUnit)
      legendValidInvalidIconRestart('input-unit-symbol-' + indexUnit, 'container-validate-unit-symbol-valid-' + indexUnit)
      legendValidInvalidIconRestart('input-unit-symbol-' + indexUnit, 'container-validate-unit-symbol-required-' + indexUnit)
    }
  }

  function uploadData() {
    if (settingsForCompany) {
      let tempTaxesValue: string[] = []
      let tempTaxesValid: boolean[] = []
      let tempDeadlinesValue: {id: string, type: string, quantity: string}[] = []
      let tempDeadlinesValid: {type: boolean, quantity: boolean}[] = []
      let tempUnitsValue: {id: string, type: string, name: string, symbol: string}[] = []
      let tempUnitsValid: {type: boolean, name: boolean, symbol: boolean}[] = []

      for(let tax of settingsForCompany.tax_fees) {
        tempTaxesValue.push(tax)
        tempTaxesValid.push(true)
      }

      for(let deadline of settingsForCompany.payment_deadlines) {
        let newDeadlineValue: {id: string, type: string, quantity: string} = {
          id: deadline.id,
          type: deadline.type,
          quantity: deadline.quantity.toString()
        }
        let newDeadlineValid: {type: boolean, quantity: boolean} = {
          type: true,
          quantity: true
        }
        tempDeadlinesValue.push(newDeadlineValue)
        tempDeadlinesValid.push(newDeadlineValid)
      }

      for(let unit of settingsForCompany.measure_units) {
        let newUnitValue: {id: string, type: string, name: string, symbol: string} = {
          id: unit.id,
          type: unit.type,
          name: unit.name,
          symbol: unit.symbol
        }
        let newUnitValid: {type: boolean, name: boolean, symbol: boolean} = {
          type: true,
          name: true,
          symbol: true
        }
        tempUnitsValue.push(newUnitValue)
        tempUnitsValid.push(newUnitValid)
      }

      setTaxes({value: tempTaxesValue, valid: tempTaxesValid})
      setDeadlines({value: tempDeadlinesValue, valid: tempDeadlinesValid})
      setUnits({value: tempUnitsValue, valid: tempUnitsValid})
    }
  }

  useEffect( () => {
    uploadData()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsForCompany])

  return (
    <div className="card">
      <div className="card-header">
        <div className="card-title fs-4 fw-bolder">{lang.labels.companyParameterSettings}</div>
      </div>
      { settingsForCompany
        ?
        <div className="form">
          <div className="card-body">
            <div className="mb-10 d-none">
              <div className="d-flex bd-highlight align-items-center mb-5">
                <div className="me-auto bd-highlight">
                  <div className="fs-6 fw-bold">{lang.labels.predefinedTaxesFees}</div>
                </div>
                <div className="bd-highlight">
                  <button className="btn btn-sm btn-primary" type="button" onClick={executeAddTax}>
                    <div className="d-flex align-items-center">
                      <span className="svg-icon svg-icon-5 ms-0 me-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black"></rect>
                          <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black"></rect>
                          <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black"></rect>
                        </svg>
                      </span>
                      {lang.labels.addTax}
                    </div>
                  </button>
                </div>
              </div>
              <table className="table align-middle table-row-dashed g-1 dataTable no-footer m-0">
                <thead>
                  <tr className="text-start text-muted fw-bolder text-uppercase">
                    <th rowSpan={1} colSpan={1} className="min-w-200px sorting_disabled">{lang.labels.value}</th>
                    <th rowSpan={1} colSpan={1} className="w-75px sorting_disabled text-end">{lang.labels.remove}</th>
                  </tr>
                </thead>
                { taxes.value.length > 0
                  ?
                  <tbody>
                    { taxes.value.map (( (item, index) => { return (
                      <tr key={index} className="border-0">
                        <td valign="top">
                          <div className="position-relative">
                            <input className="form-control form-control-solid pe-8" type="text" name="tax" value={item} onChange={(event) => handleChangeTax(index, event)} onKeyUp={() => validateTax(index)} onBlur={() => validateTax(index)} />
                            <div className="position-absolute translate-middle-y top-50 end-0 me-4">{percentSymbol(item)}</div>  
                          </div>
                          <AppLegend component={null} attribute={{validity: taxes.valid[index], name: "tax", index: index, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                        </td>
                        <td valign="top" className="text-end">
                          <button className="btn btn-icon btn-flex btn-bg-light btn-active-color-primary me-3" type="button" onClick={() => executeRemoveTax(index)}>
                            <span className="svg-icon svg-icon-3">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black"></path>
                                <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black"></path>
                                <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black"></path>
                              </svg>
                            </span>
                          </button>
                        </td>
                      </tr>
                    )}))}
                  </tbody>
                  :
                  <tbody>
                    <tr>
                      <td valign="top" colSpan={2} className="dataTables_empty">
                        <div className="text-center py-3 fs-6">
                          {lang.labels.noRecordsFound}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                }
              </table>
            </div>
            <div className="mb-10">
              <div className="d-flex bd-highlight align-items-center mb-5">
                <div className="me-auto bd-highlight">
                  <div className="fs-6 fw-bold">{lang.labels.dueDates}</div>
                </div>
                <div className="bd-highlight">
                  <button className="btn btn-sm btn-primary" type="button" onClick={executeAddDeadline}>
                    <div className="d-flex align-items-center">
                      <span className="svg-icon svg-icon-5 ms-0 me-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black"></rect>
                          <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black"></rect>
                          <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black"></rect>
                        </svg>
                      </span>
                      {lang.labels.addDeadline}
                    </div>
                  </button>
                </div>
              </div>
              <table className="table align-middle table-row-dashed g-1 dataTable no-footer m-0">
                <thead>
                  <tr className="text-start text-muted fw-bolder text-uppercase">
                    <th rowSpan={1} colSpan={1} className="w-175px sorting_disabled">{lang.labels.type}</th>
                    <th rowSpan={1} colSpan={1} className="min-w-200px sorting_disabled">{lang.labels.quantity}</th>
                    <th rowSpan={1} colSpan={1} className="w-75px sorting_disabled text-end">{lang.labels.remove}</th>
                  </tr>
                </thead>
                { deadlines.value.length > 0
                  ?
                  <tbody>
                    { deadlines.value.map (( (item, index) => { return (
                      <tr key={index} className="border-0">
                        <td valign="top">
                          <Dropdown>
                            <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                              <span className="selection">
                                <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                                  <span className="select2-selection__rendered" role="textbox">
                                    <span className={`select2-selection__placeholder ${deadlines.valid[index].type && "text-capitalize"}`}>
                                      {deadlines.valid[index].type ? deadlinetypeValue(lang, item.type) : lang.labels.selectOption}
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                              <span className="select2-dropdown select2-dropdown--below">
                                <span className="select2-results">
                                  <ul className="select2-results__options" role="listbox">
                                    { deadlinetypeList(lang).map (( (sub_item, sub_index) => { return (
                                      <li key={sub_index} className={`select2-results__option select2-results__option--selectable ${item.type === sub_item.code && "select2-results__option--selected"}`} role="option" aria-selected={item.type === sub_item.code}>
                                        <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeDeadlineType(index, sub_item.code)}>{sub_item.description}</Dropdown.Item>
                                      </li>
                                    )}))}
                                  </ul>
                                </span>
                              </span>
                            </Dropdown.Menu>
                          </Dropdown>
                          <AppLegend component={null} attribute={{validity: deadlines.valid[index].type, name: "deadline-type", index: index, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                        </td>
                        <td valign="top">
                          <input id={"input-deadline-quantity-" + index} className="form-control form-control-solid" type="text" name="quantity" value={item.quantity} onChange={(event) => handleChangeDeadlineQuantity(index, event)} onKeyUp={() => validateDeadlineQuantity(index)} onBlur={() => validateDeadlineQuantity(index)} />
                          <AppLegend component={null} attribute={{validity: deadlines.valid[index].quantity, name: "deadline-quantity", index: index, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                        </td>
                        <td valign="top" className="text-end">
                          <button className="btn btn-icon btn-flex btn-bg-light btn-active-color-primary me-3" type="button" onClick={() => executeRemoveDeadline(index)}>
                            <span className="svg-icon svg-icon-3">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black"></path>
                                <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black"></path>
                                <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black"></path>
                              </svg>
                            </span>
                          </button>
                        </td>
                      </tr>
                    )}))}
                  </tbody>
                  :
                  <tbody>
                    <tr>
                      <td valign="top" colSpan={3} className="dataTables_empty">
                        <div className="text-center py-3 fs-6">
                          {lang.labels.noRecordsFound}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                }
              </table>
            </div>
            <div className="mb-0">
              <div className="d-flex bd-highlight align-items-center mb-5">
                <div className="me-auto bd-highlight">
                  <div className="fs-6 fw-bold">{lang.labels.unitsOfMeasure}</div>
                </div>
                <div className="bd-highlight">
                  <button className="btn btn-sm btn-primary" type="button" onClick={executeAddUnit}>
                    <div className="d-flex align-items-center">
                      <span className="svg-icon svg-icon-5 ms-0 me-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black"></rect>
                          <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black"></rect>
                          <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black"></rect>
                        </svg>
                      </span>
                      {lang.labels.addUnit}
                    </div>
                  </button>
                </div>
              </div>
              <table className="table align-middle table-row-dashed g-1 dataTable no-footer m-0">
                <thead>
                  <tr className="text-start text-muted fw-bolder text-uppercase">
                    <th rowSpan={1} colSpan={1} className="w-175px sorting_disabled">{lang.labels.type}</th>
                    <th rowSpan={1} colSpan={1} className="min-w-100px sorting_disabled">{lang.labels.name}</th>
                    <th rowSpan={1} colSpan={1} className="min-w-100px sorting_disabled">{lang.labels.symbol}</th>
                    <th rowSpan={1} colSpan={1} className="w-75px sorting_disabled text-end">{lang.labels.remove}</th>
                  </tr>
                </thead>
                { units.value.length > 0
                  ?
                  <tbody>
                    { units.value.map (( (item, index) => { return (
                      <tr key={index} className="border-0">
                        <td valign="top">
                          <Dropdown>
                            <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                              <span className="selection">
                                <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                                  <span className="select2-selection__rendered" role="textbox">
                                    <span className={`select2-selection__placeholder ${units.valid[index].type && "text-capitalize"}`}>
                                      {units.valid[index].type ? requirementTypeValue(lang, item.type) : lang.labels.selectOption}
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                              <span className="select2-dropdown select2-dropdown--below">
                                <span className="select2-results">
                                  <ul className="select2-results__options" role="listbox">
                                    <li className={`select2-results__option select2-results__option--selectable ${item.type === "service" && "select2-results__option--selected"}`} role="option" aria-selected={item.type === "service"}>
                                      <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeUnitType(index, "service")}>{lang.labels.service}</Dropdown.Item>
                                    </li>
                                    <li className={`select2-results__option select2-results__option--selectable ${item.type === "product" && "select2-results__option--selected"}`} role="option" aria-selected={item.type === "product"}>
                                      <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeUnitType(index, "product")}>{lang.labels.product}</Dropdown.Item>
                                    </li>
                                  </ul>
                                </span>
                              </span>
                            </Dropdown.Menu>
                          </Dropdown>
                          <AppLegend component={null} attribute={{validity: units.valid[index].type, name: "unit-type", index: index, sub_index: null}} container={{valid: false, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                        </td>
                        <td valign="top">
                          <input id={"input-unit-name-" + index} className="form-control form-control-solid" type="text" name="name" value={item.name} onChange={(event) => handleChangeUnitName(index, event)} onKeyUp={() => validateUnitName(index)} onBlur={() => validateUnitName(index)} />
                          <AppLegend component={null} attribute={{validity: units.valid[index].name, name: "unit-name", index: index, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                        </td>
                        <td valign="top">
                          <input id={"input-unit-symbol-" + index} className="form-control form-control-solid" type="text" name="symbol" value={item.symbol} onChange={(event) => handleChangeUnitSymbol(index, event)} onKeyUp={() => validateUnitSymbol(index)} onBlur={() => validateUnitSymbol(index)} />
                          <AppLegend component={null} attribute={{validity: units.valid[index].symbol, name: "unit-symbol", index: index, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
                        </td>
                        <td valign="top" className="text-end">
                          <button className="btn btn-icon btn-flex btn-bg-light btn-active-color-primary me-3" type="button" onClick={() => executeRemoveUnit(index)}>
                            <span className="svg-icon svg-icon-3">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black"></path>
                                <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black"></path>
                                <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black"></path>
                              </svg>
                            </span>
                          </button>
                        </td>
                      </tr>
                    )}))}
                  </tbody>
                  :
                  <tbody>
                    <tr>
                      <td valign="top" colSpan={4} className="dataTables_empty">
                        <div className="text-center py-3 fs-6">
                          {lang.labels.noRecordsFound}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                }
              </table>
            </div>
          </div>
          <div className="card-footer d-flex justify-content-end">
            <button className="btn btn-light btn-active-light-primary" type="reset" onClick={executeFormRestart}>{lang.labels.discard}</button>
            <button className="btn btn-primary ms-2" type="button" data-kt-indicator={loadIndicator} onClick={submitCompanyUpdate}>
              <span className="indicator-label">
                {lang.labels.saveChanges}
              </span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
        :
        <div className="d-flex justify-content-center align-items-center w-100 h-200px">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">{lang.labels.loading}</span>
          </div>
        </div>
      }
    </div>
  )
};

export default AppCompanyUpdateParameter;
