import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextService } from './Service';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../../languages/translations/response';
import { ServiceDA } from '../../../../services/service.service';
import { Error } from '../../../../models/error.model';
import { Message } from '../../../../models/message.model';
import { Service } from '../../../../models/service.model';
import { imageService } from '../../../../scripts/image.value.script';
import { moneyFormat, taxRateFormat } from '../../../../scripts/format.value.script';
import { datetimeFormat12h } from '../../../../scripts/datetime.script';
import { languageDownloadValue } from '../../../../libraries/language.download.library';
import { discountDescription, discountType } from '../../../../libraries/discount.library';

import imgServiceDefault from '../../../../assets/images/service.png';

export interface ServiceViewPageProps {};

let errorResponse: Error, messageResponse: Message, serviceResponse: Service;

const ServiceViewPage: React.FunctionComponent<ServiceViewPageProps> = props => {
  const {setRoute, companyForUser, loadServicesForCompany} = UseOutletContextService()
  const {lang} = StateLanguage()
  const param = useParams()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [service, setService] = useState<Service | undefined | null>(null)

  const loadService = async (id_company: string, id_service: string) => {
    await ServiceDA.getService(id_company, id_service).then( (response) => {
      if (response.status === 200) {
        serviceResponse = response.data
        setService(serviceResponse)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.code),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.okGotIt,
          customClass: {confirmButton: 'btn btn-primary'}
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const submitServiceDelete = () => {
    if (companyForUser && service) {
      Swal.fire({
        title: lang.labels.youWantDeleteService,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          await ServiceDA.deleteServices(companyForUser.id, [service.id]).then( (response) => {
            if (response.status === 200) {
              messageResponse = response.data
              loadServicesForCompany(companyForUser.id)

              Swal.fire({
                title: getMessage(messageResponse.message, lang.code),
                text: lang.labels.actionCompletedReturningToPage,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions).then( () => {
                navigate('/app/inventory/service/list', {replace: true})
              })
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.code),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.okGotIt,
                customClass: {confirmButton: 'btn btn-primary'}
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  useEffect( () => {
    if(companyForUser && param.id) {
      loadService(companyForUser.id, param.id)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyForUser, param])

  useEffect( () => {
    setMounted(true)
    setRoute({path: {root: lang.labels.services, branch: lang.labels.viewService}, company: false})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { companyForUser && service
        ?
        <div className="d-flex flex-column flex-xl-row">
          <div className="d-flex flex-column flex-xl-row-auto gap-5 w-100 w-xl-350px mb-5 mb-xl-0">
            <div className="d-xl-none d-flex flex-wrap justify-content-end gap-2">
              <Link to={"/app/inventory/service/update/" + service.id} className="btn btn-primary btn-sm">{lang.labels.editService}</Link>
              <button className="btn btn-danger btn-sm" type="button" onClick={submitServiceDelete}>{lang.labels.deleteService}</button>
            </div>
            <div className="card card-flush">
              <div className="card-header pt-3">
                <div className="card-title">
                  <h3 className="text-uppercase">{service.display_name}</h3>
                </div>
                <div className="card-toolbar">
                  <div className="badge badge-light-info d-inline fs-7">{service.category.display_name}</div>
                </div>
              </div>
              <div className="card-body text-center pt-0">
                { service.photos.length > 0
                  ?
                  <Carousel fade variant="dark">
                    { service.photos.map (( (item, index) => { return (
                      <Carousel.Item key={index} bsPrefix="carousel-item d-flex justify-content-center align-items-center">
                        <img src={imageService(item)} className="d-block w-175px h-175px" alt={lang.labels.photography + index} />
                      </Carousel.Item>
                    )}))}
                  </Carousel>
                  :
                  <div className="symbol symbol-175px">
                    <div className="symbol-label">
                      <img src={imgServiceDefault} className="w-100 h-100" alt={lang.labels.photography} />
                    </div>
                  </div>
                }
                <div className="text-muted mt-2">{lang.labels.serviceReferencePictures}</div>
              </div>
            </div>
            <div className="card card-flush">
              <div className="card-header">
                <div className="card-title">
                  <h5>{lang.labels.names}</h5>
                </div>
              </div>
              <div className="card-body pt-0">
                { Object.values(service.names).every(value => value.trim() === "")
                  ?
                  <div className="text-gray-700 text-justify">
                    {lang.labels.noRecordsFound}
                  </div>
                  :
                  <>
                    { Object.entries(service.names).map (( (item, index) => { return (
                      <div key={index} className={`${index < 3 && "pb-2"} ${item[1].length === 0 && "d-none"}`}>
                        <div className="fw-bold text-gray-900">{item[1]}</div>
                        <div className="fs-8 text-muted">{languageDownloadValue(lang, item[0].toUpperCase())}</div>
                      </div>
                    )}))}
                  </>
                }
              </div>
            </div>
            <div className="card card-flush d-none d-xl-block">
              <div className="card-body">
                <div className="pb-2">
                  <div className="text-muted fs-8">{lang.labels.createdAt}:</div>
                  <div className="fw-bold text-gray-900">{datetimeFormat12h(service.created_at)}</div>
                </div>
                <div className="pb-0">
                  <div className="text-muted fs-8">{lang.labels.updatedAt}:</div>
                  <div className="fw-bold text-gray-900">{datetimeFormat12h(service.updated_at)}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column flex-row-fluid gap-5 ms-xl-5">
            <div className="d-none d-xl-flex flex-wrap justify-content-end gap-3">
              <Link to={"/app/inventory/service/update/" + service.id} className="btn btn-primary btn-sm">{lang.labels.editService}</Link>
              <button className="btn btn-danger btn-sm" type="button" onClick={submitServiceDelete}>{lang.labels.deleteService}</button>
            </div>
            <div className="card card-flush">
              <div className="card-header">
                <div className="card-title">
                  <h5>{lang.labels.details}</h5>
                </div>
              </div>
              <div className="card-body pt-0">
                <table className="table fw-bold gs-0 gy-2 m-0">
                  <tbody>
                    <tr>
                      <td className="w-150px text-muted fs-7">{lang.labels.description}:</td>
                      <td className="min-w-200px">{service.description}</td>
                    </tr>
                    <tr>
                      <td className="w-150px text-muted fs-7">{lang.labels.code}:</td>
                      <td className="min-w-200px text-capitalize">{(service.code.length > 0) ? service.code : lang.labels.none}</td>
                    </tr>
                    <tr>
                      <td className="w-150px text-muted fs-7">{lang.labels.sku}:</td>
                      <td className="min-w-200px text-capitalize">{(service.sku.length > 0) ? service.sku : lang.labels.none}</td>
                    </tr>
                    <tr>
                      <td className="w-150px text-muted fs-7">{lang.labels.codebar}:</td>
                      <td className="min-w-200px text-capitalize">{(service.codebar.length > 0) ? service.codebar : lang.labels.none}</td>
                    </tr>
                    <tr>
                      <td className="w-150px text-muted fs-7">{lang.labels.unit}:</td>
                      <td className="min-w-200px text-capitalize">{service.unit.name}</td>
                    </tr>
                    <tr>
                      <td className="w-150px text-muted fs-7">{lang.labels.price}:</td>
                      <td className="min-w-200px text-nowrap">{companyForUser.currency.symbol} {moneyFormat(service.price)}</td>
                    </tr>
                    <tr>
                      <td className="w-150px text-muted fs-7">{lang.labels.tax}:</td>
                      <td className="min-w-200px text-nowrap">{taxRateFormat(service.tax_rate)}</td>
                    </tr>
                    <tr>
                      <td className="w-150px text-muted fs-7">{lang.labels.discount}:</td>
                      <td className="min-w-200px text-capitalize text-nowrap">
                        {discountDescription(lang, companyForUser.currency, service.discount)}
                        { service.discount.type !== "none" &&
                          <span className="badge badge-light fs-9 text-uppercase ms-2">{discountType(lang, service.discount)}</span>
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card card-flush">
              <div className="card-header">
                <div className="card-title">
                  <h5>{lang.labels.tags}</h5>
                </div>
              </div>
              <div className="card-body pt-0">
                { service.tags.length > 0
                  ?
                  <>
                    { service.tags.map (( (item, index) => { return (
                      <div key={index} className={`d-flex align-items-center ${index < service.tags.length - 1 && "pb-1"}`}>
                        <span className="bullet bg-primary me-3"></span>
                        <span className="fw-bold text-gray-800">{item}</span>
                      </div>
                    )}))}
                  </>
                  :
                  <div>
                    {lang.labels.noRecordsFound}
                  </div>
                }
              </div>
            </div>
            <div className="card card-flush d-block d-xl-none">
              <div className="card-body">
                <div className="pb-2">
                  <div className="text-muted fs-8">{lang.labels.createdAt}:</div>
                  <div className="fw-bold text-gray-900">{datetimeFormat12h(service.created_at)}</div>
                </div>
                <div className="pb-0">
                  <div className="text-muted fs-8">{lang.labels.updatedAt}:</div>
                  <div className="fw-bold text-gray-900">{datetimeFormat12h(service.updated_at)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </div>
  )
};

export default ServiceViewPage;
