import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { Company } from '../models/company.model';
import { ProviderInvoice } from '../models/provider.invoice.model';
import { Currency } from '../models/currency.model';
import { Address } from '../models/address.model';
import { CompanyProviderInvoices } from '../models/company.provider.invoices.model';
import { appToken } from '../scripts/app.token.script';

export class ProviderInvoiceDA {
  static apiResponse: ApiResponse

  public static async getProviderInvoices(id_company: string, interval: string, start_date: string, end_date: string, contact_id: string, treasury_id: string, validity: string, payment: string, sort_field: string, sort_mode: string, group_by: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let providerInvoicesByCompany: CompanyProviderInvoices | null = null
      let providerInvoiceGroupsByCompany: CompanyProviderInvoices | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/purchase.invoices.list.data.json').then(response => { providerInvoicesByCompany = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/purchase.invoices.group.data.json').then(response => { providerInvoiceGroupsByCompany = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (providerInvoicesByCompany!.id_company === id_company || providerInvoiceGroupsByCompany!.id_company === id_company) {
        if (group_by.length === 0) {
          providerInvoicesByCompany!.filter.interval = interval
          providerInvoicesByCompany!.filter.start_date = start_date
          providerInvoicesByCompany!.filter.end_date = end_date
          providerInvoicesByCompany!.filter.contact_id = contact_id
          providerInvoicesByCompany!.filter.treasury_id = treasury_id
          providerInvoicesByCompany!.filter.validity = validity
          providerInvoicesByCompany!.filter.payment = payment
          providerInvoicesByCompany!.filter.sort_field = sort_field
          providerInvoicesByCompany!.filter.sort_mode = sort_mode
          providerInvoicesByCompany!.filter.group_by = group_by
        } else {
          providerInvoiceGroupsByCompany!.filter.interval = interval
          providerInvoiceGroupsByCompany!.filter.start_date = start_date
          providerInvoiceGroupsByCompany!.filter.end_date = end_date
          providerInvoiceGroupsByCompany!.filter.contact_id = contact_id
          providerInvoiceGroupsByCompany!.filter.treasury_id = treasury_id
          providerInvoiceGroupsByCompany!.filter.validity = validity
          providerInvoiceGroupsByCompany!.filter.payment = payment
          providerInvoiceGroupsByCompany!.filter.sort_field = sort_field
          providerInvoiceGroupsByCompany!.filter.sort_mode = sort_mode
          providerInvoiceGroupsByCompany!.filter.group_by = group_by
        }

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: (group_by.length === 0) ? providerInvoicesByCompany : providerInvoiceGroupsByCompany
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/provider/invoices?interval=${interval}&start_date=${start_date}&end_date=${end_date}&id_contact=${contact_id}&id_bank_account=${treasury_id}&validity=${validity}&payment=${payment}&sort_field=${sort_field}&sort_mode=${sort_mode}&group_by=${group_by}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async getProviderInvoice(id_company: string, id_provider_invoice: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let providerInvoice: ProviderInvoice | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/purchase.invoice.data.json').then(response => { providerInvoice = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (providerInvoice!.id === id_provider_invoice) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: providerInvoice
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/provider/invoices/${id_provider_invoice}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.get(url, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async postProviderInvoice(id_company: string, id_provider: string, concerning: string, date: string, currency: Currency, id_treasury_account: string, requirements: { type: string, description: string, contents: { detail: string, quantity: string, price_unit: string, tax_rate: string, tax_included: boolean, unit: string }[] }[], vouchers: { name: string, file: any }[], signatures: string[], company_data: { uuid: string, name: string, logo: string, address: Address }, reference: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let company: Company | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/company.data.json').then(response => { company = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (company!.id === id_company) {
        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/provider/invoices`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = new FormData();
      data.append("customer", id_provider)
      data.append("concerning", concerning)
      data.append('created_date', date)
      data.append("bank_account", id_treasury_account)
      data.append('reference', reference)
      data.append('currency', JSON.stringify(currency))
      data.append('company', JSON.stringify(company_data))

      requirements.forEach((item: any) => {
        data.append('requirements', JSON.stringify(item))
      })

      signatures.forEach((item: any) => {
        data.append('signatures', item)
      })

      vouchers.forEach((item: any) => {
        data.append("vouchers", item.file, item.name)
      })

      await axios.post(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async postProviderInvoiceReference(id_company: string, id_provider_order: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let providerInvoice: ProviderInvoice | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/purchase.invoice.data.json').then(response => { providerInvoice = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (providerInvoice!.provider_order.id === id_provider_order) {
        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: providerInvoice
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/provider/invoices/${id_provider_order}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.post(url, null, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putProviderInvoice(id_company: string, id_provider_invoice: string, concerning: string, date: string, currency: Currency, treasury_id: string, requirements: { type: string, description: string, contents: { detail: string, quantity: string, price_unit: string, tax_rate: string, tax_included: boolean, unit: string }[] }[], vouchers: { name: string, file: any }[], signatures: string[]): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let providerInvoice: ProviderInvoice | null = null
      let message: Message | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/purchase.invoice.data.json').then(response => { providerInvoice = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/message.data.json').then(response => { message = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (providerInvoice!.id === id_provider_invoice) {
        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: message
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/provider/invoices/${id_provider_invoice}`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      let data: any = new FormData()
      data.append("concerning", concerning)
      data.append('created_date', date)
      data.append("bank_account", treasury_id)
      data.append('currency', JSON.stringify(currency))

      requirements.forEach((item: any) => {
        data.append('requirements', JSON.stringify(item))
      })

      signatures.forEach((item: any) => {
        data.append('signatures', item)
      })

      vouchers.forEach((item: any) => {
        if (item.file instanceof File) {
          data.append("vouchers", item.file, item.name)
        } else {
          data.append("array_url", item.file)
        }
        data.append("array_names", item.name)
      })

      await axios.put(url, data, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }

  public static async putProviderInvoiceValidated(id_company: string, id_provider_invoice: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let providerInvoice: ProviderInvoice | null = null
      let error: Error | null = null

      await axios.get('../../../../../database/purchase.invoice.data.json').then(response => { providerInvoice = response.data }).catch(error => { alert(error) })
      await axios.get('../../../../../database/error.data.json').then(response => { error = response.data.error }).catch(error => { alert(error) })

      if (providerInvoice!.id === id_provider_invoice) {
        providerInvoice!.validated = (providerInvoice!.validated) ? false : true

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: providerInvoice
        }
      } else {
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: error
        }
      }
    } else {
      let url: string = `${process.env.REACT_APP_PORT_BACKEND}/${id_company}/provider/invoices/${id_provider_invoice}/status`

      let config: any = {
        headers: { Authorization: appToken() }
      }

      await axios.put(url, null, config).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    }

    return this.apiResponse
  }
}
