import React from 'react';

import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { ProviderOrder } from '../../../../models/provider.order.model';
import { ProviderInvoice } from '../../../../models/provider.invoice.model';
import { Expense } from '../../../../models/expense.model';
import { dateFormatAbbreviated, datetimeFormat12h } from '../../../../scripts/datetime.script';
import { countryName } from '../../../../libraries/countries.library';
import { paymentMethodValue } from '../../../../libraries/payment.library';
import { contactTypeValue } from '../../../../libraries/contatc.type.library';

export interface AppPurchaseViewDetailProps {
  purchase: string,
  record: any | ProviderOrder | ProviderInvoice | Expense
};

const AppPurchaseViewDetail: React.FunctionComponent<AppPurchaseViewDetailProps> = ({purchase, record}) => {
  const {lang} = StateLanguage()

  const labels: {[key: string]: {reference: string, date: string}} = {
    order: {reference: lang.labels.providerOrderReference, date: lang.labels.providerOrderDate},
    invoice: {reference: lang.labels.providerInvoiceReference, date: lang.labels.providerInvoiceDate},
    expense: {reference: lang.labels.expenseReference, date: lang.labels.expenseDate}
  }

  return (
    <div className="card-body">
      <div className="d-flex flex-wrap">
        <div className="flex-equal me-5">
          <table className="table align-middle gy-1 m-0">
            <tbody className="fw-bold">
              { purchase === "invoice" &&
                <tr>
                  <td className="w-200px text-gray-500">{lang.labels.providerOrderNumber}:</td>
                  <td className="min-w-200px text-gray-800">{record.provider_order.id.length > 0 ? record.provider_order.provider_order_number : lang.labels.none}</td>
                </tr>
              }
              { (purchase === "order" || purchase === "invoice") &&
                <tr>
                  <td className="w-200px text-gray-500">{labels[purchase].reference}:</td>
                  <td className="min-w-200px text-gray-800">{record.reference.length > 0 ? record.reference : lang.labels.none}</td>
                </tr>
              }
              <tr>
                <td className="w-200px text-gray-500">{lang.labels.concerning}:</td>
                <td className="min-w-200px text-gray-800">{record.concerning}</td>
              </tr>
              <tr>
                <td className="w-200px text-gray-500">{lang.labels.company}:</td>
                <td className="min-w-200px text-gray-800">{record.company.name}</td>
              </tr>
              { (purchase === "order" || purchase === "invoice") &&
                <tr>
                  <td  valign="top" className="w-200px text-gray-500">{lang.labels.provider}:</td>
                  <td className="min-w-200px text-gray-800">
                    <div className="d-flex align-items-center">
                      {record.provider.type === "person" ? `${record.provider.data.first_name} ${record.provider.data.last_name}` : `${record.provider.data.name}`}
                      <span className="badge badge-sm badge-light text-uppercase fs-9 ms-3">{contactTypeValue(lang, record.provider.type)}</span>
                    </div>
                    <p className="text-gray-700 fs-8 p-0 m-0">{record.provider.data.address.street} {record.provider.data.address.number}</p>
                    <p className="text-gray-700 fs-8 p-0 m-0">{record.provider.data.address.city} - {record.provider.data.address.zip_code}</p>
                    <p className="text-gray-700 fs-8 p-0 m-0">{countryName(record.provider.data.address.country, lang.code)}</p>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
        <div className="flex-equal">
          <table className="table align-middle gy-1 m-0">
            <tbody className="fw-bold">
              <tr>
                <td className="w-200px text-gray-500">{labels[purchase].date}:</td>
                <td className="min-w-200px text-gray-800">{dateFormatAbbreviated(record.created_date)}</td>
              </tr>
              <tr>
                <td className="w-200px text-gray-500">{lang.labels.createdAt}:</td>
                <td className="min-w-200px text-gray-800">{datetimeFormat12h(record.created_at)}</td>
              </tr>
              <tr>
                <td className="w-200px text-gray-500">{lang.labels.currency}:</td>
                <td className="min-w-200px text-gray-800">{record.currency.code}: {record.currency.name} ({record.currency.symbol})</td>
              </tr>
              <tr>
                <td className="w-200px text-gray-500">{lang.labels.myBankAccount}:</td>
                <td className="min-w-200px text-gray-800">{record.treasury_account.name}</td>
              </tr>
              { (purchase === "order" || purchase === "invoice") &&
                <tr>
                  <td className="w-200px text-gray-500">{lang.labels.providerBankAccount}:</td>
                  <td className="min-w-200px text-gray-800">{record.provider_treasury_account.financial_entity}</td>
                </tr>
              }
              { purchase === "expense" &&
                <tr>
                  <td className="w-200px text-gray-500">{lang.labels.paymentMethod}:</td>
                  <td className="min-w-200px text-gray-800">{paymentMethodValue(lang, record.payment_method)}</td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
};

export default AppPurchaseViewDetail;
