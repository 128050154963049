import React, { Dispatch, SetStateAction, useState } from 'react';
import Cookies from 'universal-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { FaSignOutAlt } from 'react-icons/fa';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { DispatchLanguage } from '../../languages/config/DispatchLanguage';
import { StateLanguage } from '../../languages/config/StateLanguage';
import { User } from '../../models/user.model';
import { dropdownActiveHide } from '../../tools/dropdown.tool';
import { drawerHide } from '../../tools/drawer.tool';
import { imageUser } from '../../scripts/image.value.script';
import { imageLanguage } from '../../scripts/image.value.script';
import { appLanguageList, appLanguageUpload, appLanguageValue } from '../../scripts/app.language.script';

export interface AppDropdownUserProfileProps {
  activeDrawer: {value: string, aside: string, button: string},
  setActiveDrawer: Dispatch<SetStateAction<{value: string, aside: string, button: string}>>,
  userLoggedIn: User | undefined | null
};

const AppDropdownUserProfile: React.FunctionComponent<AppDropdownUserProfileProps> = ({activeDrawer, setActiveDrawer, userLoggedIn}) => {
  const dispatchLanguage = DispatchLanguage()
  const {lang} = StateLanguage()
  const navigate = useNavigate()
  const cookies = new Cookies()

  const [language, setLanguage] = useState('')

  const submitLanguage = (item: string) => {
    if (item !== language) {
      navigate(0)
      setLanguage(item)
      localStorage.setItem('language', item)
    } else {
      dropdownActiveHide('button-user-profile', 'dropdown-user-profile', 'overlay-menu')
    }
  }

  const submitSignOut = () => {
    Swal.fire({
      title: lang.labels.youWantLogOut,
      text: lang.labels.willNotBeAbleToReturnUntilYouLogin,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: lang.labels.yesLogOut,
      cancelButtonText: lang.labels.noCancel,
      customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
    }).then( (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: lang.labels.youHaveSuccessfullySignedOut,
          text: lang.labels.redirectingToLogin,
          icon: 'success',
          showConfirmButton: false,
          timer: 1800
        } as SweetAlertOptions).then( () => {
          cookies.remove('token', {path: '/', sameSite: 'lax'})
          cookies.remove('expires_at', {path: '/', sameSite: 'lax'})
          cookies.remove('app_service', {path: '/', sameSite: 'lax'})
          cookies.remove('company', {path: '/', sameSite: 'lax'})
          navigate('/auth/login')
        })
      }
    })
  }

  const executeDropdownHide = () => {
    dropdownActiveHide('button-user-profile', 'dropdown-user-profile', 'overlay-menu')

    if (window.innerWidth < 992) {
      setActiveDrawer({...activeDrawer, value: 'off', aside: 'off'})
      drawerHide('navbar-aside', 'overlay-drawer')
    }
  }

  function uploadLanguage() {
    setLanguage(appLanguageUpload(dispatchLanguage))
  }

  return (
    <div id="dropdown-user-profile" className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold p-5 w-275px" data-kt-menu="true" onLoad={uploadLanguage}>
      <div className="menu-item">
        { userLoggedIn &&
          <div className="menu-content d-flex align-items-center p-0">
            <div className="symbol symbol-50px me-3">
              <img src={imageUser(userLoggedIn.avatar)} alt={lang.labels.avatar} />
            </div>
            <div className="d-flex flex-column">
              <div className="fw-bolder d-flex align-items-center fs-5">
                {userLoggedIn.first_name.split(" ", 1)} {userLoggedIn.last_name.split(" ", 1)}
                { userLoggedIn.verified 
                  ?
                  <span className="badge badge-light-success fw-bolder fs-10 px-2 py-1 ms-3">
                    <small className="text-uppercase">{lang.labels.verified}</small>
                  </span>
                  :
                  <span className="badge badge-light-warning fw-bolder fs-10 px-2 py-1 ms-3">
                    <small className="text-uppercase">{lang.labels.notVerified}</small>
                  </span>
                }
              </div>
              <span className="fw-bold text-muted fs-8">{userLoggedIn.email}</span>
            </div>
          </div>
        }
      </div>
      <div className="separator my-2"></div>
      <div className="menu-item">
        <Link to="/app/account/view" className="menu-link px-3" onClick={executeDropdownHide}>{lang.labels.myProfile}</Link>
      </div>
      <div className="menu-item">
        <Link to="/app/account/update" className="menu-link px-3" onClick={executeDropdownHide}>{lang.labels.accountSettings}</Link>
      </div>
      <div className="separator my-2"></div>
      <div className="menu-item">
        <Dropdown drop="end">
          <Dropdown.Toggle variant="none" bsPrefix="w-100 p-0">
            <div className="menu-link px-3">
              <span className="menu-title position-relative">
                {lang.labels.language}
                <span className="text-capitalize fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
                  {appLanguageValue(lang, language)}
                  <img src={imageLanguage(language.toLowerCase())} alt={lang.labels.language} className="w-15px h-15px rounded-1 ms-2 d-none" />
                  <span className="svg-icon btn-icon svg-icon-dark svg-icon-2 ms-2">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3" d="M18.4 5.59998C21.9 9.09998 21.9 14.8 18.4 18.3C14.9 21.8 9.2 21.8 5.7 18.3L18.4 5.59998Z" fill="black"/>
                      <path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM19.9 11H13V8.8999C14.9 8.6999 16.7 8.00005 18.1 6.80005C19.1 8.00005 19.7 9.4 19.9 11ZM11 19.8999C9.7 19.6999 8.39999 19.2 7.39999 18.5C8.49999 17.7 9.7 17.2001 11 17.1001V19.8999ZM5.89999 6.90002C7.39999 8.10002 9.2 8.8 11 9V11.1001H4.10001C4.30001 9.4001 4.89999 8.00002 5.89999 6.90002ZM7.39999 5.5C8.49999 4.7 9.7 4.19998 11 4.09998V7C9.7 6.8 8.39999 6.3 7.39999 5.5ZM13 17.1001C14.3 17.3001 15.6 17.8 16.6 18.5C15.5 19.3 14.3 19.7999 13 19.8999V17.1001ZM13 4.09998C14.3 4.29998 15.6 4.8 16.6 5.5C15.5 6.3 14.3 6.80002 13 6.90002V4.09998ZM4.10001 13H11V15.1001C9.1 15.3001 7.29999 16 5.89999 17.2C4.89999 16 4.30001 14.6 4.10001 13ZM18.1 17.1001C16.6 15.9001 14.8 15.2 13 15V12.8999H19.9C19.7 14.5999 19.1 16.0001 18.1 17.1001Z" fill="black"/>
                    </svg>
                  </span>
                </span>
              </span>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu variant="menu-sub p-3 w-150px" flip align="end">
            { appLanguageList().map (( (item, index) => { return (
              <div key={index} className="menu-item">
                <Dropdown.Item bsPrefix="menu-link d-flex px-3" active={language === item.code} onClick={() => submitLanguage(item.code)}>
                  <span className="symbol symbol-20px me-3">
                    <img src={imageLanguage("english")} alt={lang.labels.english} className="w-15px h-15px rounded-1 ms-2 d-none" />
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3" d="M6 22H4V3C4 2.4 4.4 2 5 2C5.6 2 6 2.4 6 3V22Z" fill="black"/>
                      <path d="M18 14H4V4H18C18.8 4 19.2 4.9 18.7 5.5L16 9L18.8 12.5C19.3 13.1 18.8 14 18 14Z" fill="black"/>
                    </svg>
                  </span>
                  {item.description}
                </Dropdown.Item>
              </div>
            )}))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="separator my-2"></div>
      <div className="menu-item">
        <div className="menu-content menu-link px-3" onClick={submitSignOut}>
          <label className="form-check form-check-custom pulse pulse-success">
            <FaSignOutAlt className="text-black fs-3"></FaSignOutAlt>
            <span className="pulse-ring ms-n1"></span>
            <span className="form-check-label text-gray-900 fs-7">{lang.labels.signOut}</span>
          </label>
        </div>
      </div>
    </div>
  )
}

export default AppDropdownUserProfile;
