import React, { Dispatch, SetStateAction } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import { UseOutletContextInventory } from '../Inventory';
import { AppService } from '../../../../models/app.service.model';
import { Company } from '../../../../models/company.model';
import { Product } from '../../../../models/product.model';
import { Service } from '../../../../models/service.model';
import { Category } from '../../../../models/category.model';

export interface CategoryPageProps {};

type ContexType = {
  setRoute: Dispatch<SetStateAction<{path: {root: string, branch: string} | null, company: boolean} | null>>,
  appServiceForUser: AppService | undefined | null,
  companyForUser: Company | undefined | null,
  servicesForCompany: Service[] | undefined | null,
  productsForCompany: Product[] | undefined | null,
  categoriesForCompany: Category[] | undefined | null,
  setCategoriesForCompany: Dispatch<SetStateAction<Category[] | undefined | null>>,
  loadServicesForCompany: Function,
  loadProductsForCompany: Function
};

const CategoryPage: React.FunctionComponent<CategoryPageProps> = props => {
  const {setRoute, appServiceForUser, companyForUser, servicesForCompany, productsForCompany, categoriesForCompany, setCategoriesForCompany, loadServicesForCompany, loadProductsForCompany} = UseOutletContextInventory()

  return (
    <Outlet context={{setRoute, appServiceForUser, companyForUser, servicesForCompany, productsForCompany, categoriesForCompany, setCategoriesForCompany, loadServicesForCompany, loadProductsForCompany}}></Outlet>
  );
}

export function UseOutletContextCategory() {
  return useOutletContext<ContexType>()
};

export default CategoryPage;
