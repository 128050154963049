import { Language } from "../languages/labels/language.labels";
import { GroupingOptions } from "../models/grouping.options.model"
import { statusRecordValue } from "./status.record.library";
import { validationValue } from "./validation.library";
import { expirationValue } from "./expiration.library";
import { monthValue } from "./month.library";
import { enumerationValue } from "./enumeration.library";
import { paymentAllValue } from "./payment.library";

export function nameGroup(lang: Language, key: string, group_detail: GroupingOptions): string {
  switch (key) {
    case 'customer':
      return `${group_detail.customer}`
    case 'provider':
      return `${group_detail.provider}`
    case 'treasury':
      return `${group_detail.treasury}`
    case 'status':
      return `${statusRecordValue(lang, group_detail.status)}`
    case 'expiration':
      return `${expirationValue(lang, group_detail.expiration)}`
    case 'validity':
      return `${validationValue(lang, group_detail.validity)}`
    case 'payment':
      return `${paymentAllValue(lang, group_detail.payment)}`
    case 'year':
      return `${group_detail.year}`
    case 'half':
      return `${group_detail.year} - ${enumerationValue(lang, group_detail.half)} ${lang.labels.halfYear}`
    case 'trimester':
      return `${group_detail.year} - ${enumerationValue(lang, group_detail.trimester)} ${lang.labels.trimester}`
    case 'bimester':
      return `${group_detail.year} - ${enumerationValue(lang, group_detail.bimester)} ${lang.labels.bimester}`
    case 'month':
      return `${group_detail.year} - ${monthValue(lang, group_detail.month)}`
    case 'week':
      return `${group_detail.year} - ${lang.labels.week} ${group_detail.week.padStart(2, '0')}`
    case 'day':
      return `${group_detail.year} - ${monthValue(lang, group_detail.month)} - ${group_detail.day.padStart(2, '0')}`
    default:
      return lang.labels.notGrouped
  }
}
