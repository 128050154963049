import React, { Dispatch, SetStateAction, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { Settings } from '../../models/settings.model';
import { legendInvalid, legendInvalidIcon, legendValidInvalidIconRestart, legendValidInvalidRestart } from '../../tools/legend.data.entry.tool';
import { modalHide } from '../../tools/modal.tool';
import { uploadTooltip } from '../../tools/tooltip.tool';
import { validateAttributes } from '../../scripts/validate.attributes.script';

import AppProductVariantForm from '../../interfaces/main/inventory/product/card/ProductVariantForm';

export interface AppModalProductVariantAddProps {
  settingsForCompany: Settings | null | undefined,
  newTax: {main_modal: string | null, index: number | null, sub_index: number | null},
  newUnit: {type: string, main_modal: string | null, index: number | null, sub_index: number | null},
  variantDefault: {value: {description_short: string, description_long: string, code: string, sku: string, codebar: string, size: string, color: string, price: string, tax_rate: string, unit: string, discount: {type: string, value: string, quantity: string}, photos: {view: string, file: any}[]}, valid: {description_short: boolean, description_long: boolean, code: boolean, sku: boolean, codebar: boolean, size: boolean, color: boolean, price: boolean, tax_rate: boolean, unit: boolean, discount: {type: boolean, value: boolean, quantity: boolean}, photos: {file: boolean, message: string}[], gallery: boolean}},
  variant: {value: {description_short: string, description_long: string, code: string, sku: string, codebar: string, size: string, color: string, price: string, tax_rate: string, unit: string, discount: {type: string, value: string, quantity: string}, photos: {view: string, file: any}[]}, valid: {description_short: boolean, description_long: boolean, code: boolean, sku: boolean, codebar: boolean, size: boolean, color: boolean, price: boolean, tax_rate: boolean, unit: boolean, discount: {type: boolean, value: boolean, quantity: boolean}, photos: {file: boolean, message: string}[], gallery: boolean}},
  variants: {value: {description_short: string, description_long: string, code: string, sku: string, codebar: string, size: string, color: string, price: string, tax_rate: string, unit: string, discount: {type: string, value: string, quantity: string}, photos: {view: string, file: any}[]}[], valid: {description_short: boolean, description_long: boolean, code: boolean, sku: boolean, codebar: boolean, size: boolean, color: boolean, price: boolean, tax_rate: boolean, unit: boolean, discount: {type: boolean, value: boolean, quantity: boolean}, photos: {file: boolean, message: string}[], gallery: boolean}[]},
  setNewTax: Dispatch<SetStateAction<{main_modal: string | null, index: number | null, sub_index: number | null}>>,
  setNewUnit: Dispatch<SetStateAction<{type: string, main_modal: string | null, index: number | null, sub_index: number | null}>>,
  setVariant: Dispatch<SetStateAction<{value: {description_short: string, description_long: string, code: string, sku: string, codebar: string, size: string, color: string, price: string, tax_rate: string, unit: string, discount: {type: string, value: string, quantity: string}, photos: {view: string, file: any}[]}, valid: {description_short: boolean, description_long: boolean, code: boolean, sku: boolean, codebar: boolean, size: boolean, color: boolean, price: boolean, tax_rate: boolean, unit: boolean, discount: {type: boolean, value: boolean, quantity: boolean}, photos: {file: boolean, message: string}[], gallery: boolean}}>>,
  setVariants: Dispatch<SetStateAction<{value: {description_short: string, description_long: string, code: string, sku: string, codebar: string, size: string, color: string, price: string, tax_rate: string, unit: string, discount: {type: string, value: string, quantity: string}, photos: {view: string, file: any}[]}[], valid: {description_short: boolean, description_long: boolean, code: boolean, sku: boolean, codebar: boolean, size: boolean, color: boolean, price: boolean, tax_rate: boolean, unit: boolean, discount: {type: boolean, value: boolean, quantity: boolean}, photos: {file: boolean, message: string}[], gallery: boolean}[]}>>
};

const AppModalProductVariantAdd: React.FunctionComponent<AppModalProductVariantAddProps> = ({settingsForCompany, newTax, newUnit, variantDefault, variant, variants, setNewTax, setNewUnit, setVariant, setVariants}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')

  const submitProductVariantAdd = async () => {
    setLoadIndicator('on')

    if (validateAttributes(variant.valid)) {
      if (variants.value.length === 0 && variants.valid.length === 0) {
        setVariants({value: [variantDefault.value, variant.value], valid: [variantDefault.valid, variant.valid]})
      } else {
        setVariants({value: [...variants.value, variant.value], valid: [...variants.valid, variant.valid]})
      }

      setLoadIndicator('off')
      executeHideModalProductVariantAdd()
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (variant.value.description_short.length === 0) {
          legendInvalidIcon('modal-product-variant-add-input-variant-description-short', 'modal-product-variant-add-container-validate-variant-description-short-required')
        }
        if (variant.value.unit.length === 0) {
          legendInvalid('modal-product-variant-add-container-validate-variant-unit-required')
        }
        if (variant.value.tax_rate.length === 0) {
          legendInvalid('modal-product-variant-add-container-validate-variant-tax-required')
        }
        if (variant.value.price.length === 0) {
          legendInvalidIcon('modal-product-variant-add-input-variant-price', 'modal-product-variant-add-container-validate-variant-price-required')
        }
        if (variant.value.discount.type !== 'none') {
          if (variant.value.discount.value.length === 0) {
            legendInvalidIcon('modal-product-variant-add-input-variant-discount-value', 'modal-product-variant-add-container-validate-variant-discount-value-required')
          }
          if (variant.value.discount.quantity.length === 0) {
            legendInvalidIcon('modal-product-variant-add-input-variant-discount-quantity', 'modal-product-variant-add-container-validate-variant-discount-quantity-required')
          }
        }
        setLoadIndicator('off')
      })
    }
  }

  const executeHideModalProductVariantAdd = () => {
    modalHide('modal-product-variant-add')

    setTimeout( () => {
      restartModal()
    }, 200 )
  }

  function restartModal() {
    legendValidInvalidIconRestart('modal-product-variant-add-input-variant-description-short', 'modal-product-variant-add-container-validate-variant-description-short-valid')
    legendValidInvalidIconRestart('modal-product-variant-add-input-variant-description-short', 'modal-product-variant-add-container-validate-variant-description-short-required')
    legendValidInvalidIconRestart('modal-product-variant-add-input-variant-description-long', 'modal-product-variant-add-container-validate-variant-description-long-valid')
    legendValidInvalidIconRestart('modal-product-variant-add-input-variant-code', 'modal-product-variant-add-container-validate-variant-code-valid')
    legendValidInvalidIconRestart('modal-product-variant-add-input-variant-sku', 'modal-product-variant-add-container-validate-variant-sku-valid')
    legendValidInvalidIconRestart('modal-product-variant-add-input-variant-codebar', 'modal-product-variant-add-container-validate-variant-codebar-valid')
    legendValidInvalidIconRestart('modal-product-variant-add-input-variant-size', 'modal-product-variant-add-container-validate-variant-size-valid')
    legendValidInvalidIconRestart('modal-product-variant-add-input-variant-color', 'modal-product-variant-add-container-validate-variant-color-valid')
    legendValidInvalidIconRestart('modal-product-variant-add-input-variant-price', 'modal-product-variant-add-container-validate-variant-price-valid')
    legendValidInvalidIconRestart('modal-product-variant-add-input-variant-price', 'modal-product-variant-add-container-validate-variant-price-required')
    legendValidInvalidIconRestart('modal-product-variant-add-input-variant-discount-value', 'modal-product-variant-add-container-validate-variant-discount-value-valid')
    legendValidInvalidIconRestart('modal-product-variant-add-input-variant-discount-value', 'modal-product-variant-add-container-validate-variant-discount-value-required')
    legendValidInvalidIconRestart('modal-product-variant-add-input-variant-discount-quantity', 'modal-product-variant-add-container-validate-variant-discount-quantity-valid')
    legendValidInvalidIconRestart('modal-product-variant-add-input-variant-discount-quantity', 'modal-product-variant-add-container-validate-variant-discount-quantity-required')
    legendValidInvalidRestart('modal-product-variant-add-container-validate-variant-unit-required')
    legendValidInvalidRestart('modal-product-variant-add-container-validate-variant-tax-required')
    legendValidInvalidRestart('modal-product-variant-add-container-validate-date-required')
    legendValidInvalidRestart('modal-product-variant-add-container-validate-variant-gallery-max')

    setNewTax({...newTax, main_modal: null})
    setNewUnit({...newUnit, main_modal: null})
    setVariant({value: {description_short: '', description_long: '', code: '', sku: '', codebar: '', size: '', color: '', price: '', tax_rate: '', unit: '', discount: {type: 'none', value: '', quantity: ''}, photos: []}, valid: {description_short: false, description_long: true, code: true, sku: true, codebar: true, size: true, color: true, price: false, tax_rate: false, unit: false, discount: {type: true, value: true, quantity: true}, photos: [], gallery: true}})
  }

  return (
    <div id="modal-product-variant-add" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="text-capitalize">{lang.labels.addNewProductVariant}</h2>
          </div>
          <div className="modal-body">
            { settingsForCompany
              ?
              <div className="form" onLoad={uploadTooltip}>
                <AppProductVariantForm component={"modal-product-variant-add"} newUnit={newUnit} setNewUnit={setNewUnit} variant={variant} setVariant={setVariant}></AppProductVariantForm>
              </div>
              :
              <div className="d-flex justify-content-center align-items-center w-100 h-200px">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">{lang.labels.loading}</span>
                </div>
              </div>
            }
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-light mx-2" type="reset" onClick={executeHideModalProductVariantAdd}>{lang.labels.discard}</button>
            <button className="btn btn-primary mx-2" type="button" data-kt-indicator={loadIndicator} onClick={submitProductVariantAdd}>
              <span className="indicator-label">{lang.labels.saveVariant}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppModalProductVariantAdd;
