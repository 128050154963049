import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';

import { StateLanguage } from '../languages/config/StateLanguage';
import { User } from '../models/user.model';
import { AppService } from '../models/app.service.model';
import { Company } from '../models/company.model';
import { Notification } from '../models/notification.model';
import { Container } from '../styles/container.style';
import { Footer } from '../styles/footer.style';
import { headerShow, headerHide } from '../tools/header.visibility.tool';
import { navBarShow, navBarHide } from '../tools/nav.bar.tool';
import { dropdownActiveShow, dropdownHide, dropdownShow } from '../tools/dropdown.tool';
import { navTabReload } from '../tools/nav.tab.tool';
import { accordions } from '../tools/accordion.tool';
import { removeTooltip, uploadTooltip } from '../tools/tooltip.tool';
import { drawerHide } from '../tools/drawer.tool';
import { imageUser } from '../scripts/image.value.script';
import { imageCompany } from '../scripts/image.value.script';
import { countryName } from '../libraries/countries.library';

import logo from '../assets/images/svg/logo-official-light.svg';
import imgTreasury from '../assets/images/themes/image-card.png';
import imgContacts from '../assets/images/themes/image-cell-phone.png';
import imgSales from '../assets/images/themes/image-save-money.png';
import imgPurchases from '../assets/images/themes/image-shopping.png';
import imgInventory from '../assets/images/themes/image-chat-inventory.png';

export interface AppNavbarProps {
  asideMinimize: string,
  scrollVisibility: {header: string, top: string},
  activeDrawer: {value: string, aside: string, button: string},
  setAsideMinimize: Dispatch<SetStateAction<string>>,
  setScrollVisibility: Dispatch<SetStateAction<{header: string, top: string}>>,
  setActiveDrawer: Dispatch<SetStateAction<{value: string, aside: string, button: string}>>,
  route: {path: {root: string, branch: string} | null, company: boolean} | null,
  userLoggedIn: User | undefined | null,
  appServiceForUser: AppService | undefined | null,
  companyForUser: Company | undefined | null,
  notificationsForUser: Notification[] | undefined | null,
  loadAppServiceForUser: Function,
  loadCompanyForUser: Function,
  loadNotificationsForUser: Function
};

const AppNavbar: React.FunctionComponent<AppNavbarProps> = ({asideMinimize, scrollVisibility, activeDrawer, setAsideMinimize, setScrollVisibility, setActiveDrawer, route, userLoggedIn, appServiceForUser, companyForUser, notificationsForUser, loadAppServiceForUser, loadCompanyForUser, loadNotificationsForUser}) => {
  const {lang} = StateLanguage()
  const location = useLocation()
  const navigate = useNavigate()

  const [footerNavbar, setFooterNavbar] = useState(false)
  const [searchAnimation, setSearchAnimation] = useState({spinner: false, clear: false})
  const [searchData, setSearchData] = useState({text: '', list_app_services: [] as AppService[], list_companies: [] as Company[]})

  const submitCompanySelected = (item: Company) => {
    if (route && route.company && appServiceForUser && companyForUser && companyForUser.id !== item.id) {
      loadCompanyForUser(appServiceForUser.api_key, item.id, () => {})
    }

    executeNavbarAsideHide()
  }

  const submitCompanySelectedInSearch = (item: Company) => {
    setSearchData({text: '', list_app_services: [], list_companies: []})
    setSearchAnimation({spinner: false, clear: false})
    submitCompanySelected(item)
    executeOverlayTransparent()
  }

  const handleChangeSearch = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let listAppServices: AppService[] = []
    let listCompanies: Company[] = []

    if (text.length > 0) {
      setSearchAnimation({spinner: true, clear: false})

      if (userLoggedIn) {
        for (let appService of userLoggedIn.app_services) {
          let name: string = appService.name.toLowerCase()
          if (name.indexOf(text.toLowerCase()) !== -1) {
            listAppServices.push(appService)
          }
        }
        for (let company of userLoggedIn.companies) {
          let name: string = company.name.toLowerCase()
          if (name.indexOf(text.toLowerCase()) !== -1) {
            listCompanies.push(company)
          }
        }
      }

      setTimeout( () => {
        setSearchAnimation({spinner: false, clear: true})
      }, 200 )

      dropdownShow('dropdown-search', 'container-search', 'overlay-menu-transparent')
    } else {
      setSearchAnimation({spinner: false, clear: false})
      dropdownHide('dropdown-search', 'container-search', 'overlay-menu-transparent')
    }

    setSearchData({text: text, list_app_services: listAppServices, list_companies: listCompanies})
  }

  const executeClearSearch = () => {
    setSearchAnimation({spinner: false, clear: false})
    setSearchData({text: '', list_app_services: [], list_companies: []})
    executeOverlayTransparent()
  }

  const executeClickOnTab = (item: string) => {
    let locationNavTab: string = (location.pathname.split('/')[2] === 'app') ? location.pathname.split('/')[3] : location.pathname.split('/')[2]

    if (userLoggedIn && userLoggedIn.companies.length > 0 && item === 'nav-tab-companies') {
      setFooterNavbar(true)
    } else {
      setFooterNavbar(false)
    }

    if (userLoggedIn && userLoggedIn.companies.length> 0 && window.innerWidth > 992) {
      switch (item) {
        case 'nav-tab-companies':
          if (asideMinimize === 'on') {
            setAsideMinimize('off')
            navBarHide('button-navbar-aside-minimize')
          }
          break;
        case 'nav-tab-dashboard':
          if (locationNavTab !== 'dashboard' && locationNavTab !== 'company') {
            navigate('/app/dashboard')
          }
          break;
        case 'nav-tab-contacts':
          if (locationNavTab !== 'contact') {
            navigate('/app/contact/list')
          }
          break;
        case 'nav-tab-treasury':
          if (locationNavTab !== 'treasury') {
            navigate('/app/treasury/list')
          }
          break;
        case 'nav-tab-sales':  
          if (locationNavTab !== 'sale') {
            navigate('/app/sale/quote/list')
          }
          break;
        case 'nav-tab-purchases':  
          if (locationNavTab !== 'purchase') {
            navigate('/app/purchase/order/list')
          }
          break;
        case 'nav-tab-inventory':  
          if (locationNavTab !== 'inventory') {
            navigate('/app/inventory/category/list')
          }
          break;
      }
    }
  }

  const executeOptionAccordion = (item: number) => {
    let elementsMenu: string[] = []
    let elementsSubMenu: string[] = []

    Array.from(document.querySelectorAll('div[data-type="menu-accordion"]')).forEach( (node) => {
      elementsMenu.push(node.id)
    })
    Array.from(document.querySelectorAll('div[data-type="menu-sub-accordion"]')).forEach( (node) => {
      elementsSubMenu.push(node.id)
    })

    accordions(elementsMenu, elementsSubMenu, 'menu-accordion-' + item, 'menu-sub-accordion-' + item)
  }

  const executeOptionAccordionPages = (item: number) => {
    let elementsMenu: string[] = []
    let elementsSubMenu: string[] = []

    Array.from(document.querySelectorAll('div[data-type="menu-accordion-pages"]')).forEach( (node) => {
      elementsMenu.push(node.id)
    })
    Array.from(document.querySelectorAll('div[data-type="menu-sub-accordion-pages"]')).forEach( (node) => {
      elementsSubMenu.push(node.id)
    })

    accordions(elementsMenu, elementsSubMenu, 'menu-accordion-pages-' + item, 'menu-sub-accordion-pages-' + item)
  }

  const executeNotifications = () => {
    if (appServiceForUser) {
      loadNotificationsForUser(appServiceForUser.api_key)
      dropdownActiveShow('button-notifications', 'dropdown-notifications', 'overlay-menu')
    }
  }

  const executeLanguages = () => {
    dropdownActiveShow('button-languages', 'dropdown-languages', 'overlay-menu')
  }

  const executeUserProfile = () => {
    dropdownActiveShow('button-user-profile', 'dropdown-user-profile', 'overlay-menu')
  }

  const executeNavbarAsideHide = () => {
    if (window.innerWidth < 992) {
      setActiveDrawer({...activeDrawer, value: 'off', aside: 'off'})
      drawerHide('navbar-aside', 'overlay-drawer')
    }
  }

  const executeNavbarAsideMinimize = () => {
    if (asideMinimize === 'off') {
      setAsideMinimize('on')
      navBarShow('button-navbar-aside-minimize')
    } else {
      setAsideMinimize('off')
      navBarHide('button-navbar-aside-minimize')
    }
  }

  const executeOverlayTransparent = () => {
    dropdownHide('dropdown-search', 'container-search', 'overlay-menu-transparent')
  }

  function countNotifications(): number {
    return (notificationsForUser) ? notificationsForUser.filter((item: Notification) => (!item.view && item.description === 'company access')).length : 0
  }

  function toggleVisible() {
    const scrolled = document.documentElement.scrollTop

    if (scrolled > 200) {
      setScrollVisibility({...scrollVisibility, header: 'on', top: 'on'})
      headerShow('wrapper-header')
    }  else if (scrolled <= 200) {
      setScrollVisibility({...scrollVisibility, header: 'off', top: 'off'})
      headerHide('wrapper-header')
    }
  }

  function handleResize() {
    const width = window.innerWidth

    if (width > 992) {
      setActiveDrawer({...activeDrawer, value: 'off', aside: 'off'})
      drawerHide('navbar-aside', 'overlay-drawer')
    }
  }

  useEffect( () => {
    window.addEventListener('scroll', toggleVisible)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('scroll', toggleVisible)
      window.removeEventListener('resize', handleResize)
    }
  })

  useEffect( () => {
    let listNavTab: string[] = ['nav-tab-companies', 'nav-tab-dashboard', 'nav-tab-contacts', 'nav-tab-treasury', 'nav-tab-sales', 'nav-tab-purchases', 'nav-tab-inventory']
    let listContainerAside: string[] = ['nav-tab-content-companies', 'nav-tab-content-dashborad', 'nav-tab-content-contacts', 'nav-tab-content-treasury', 'nav-tab-content-sales', 'nav-tab-content-purchases', 'nav-tab-content-inventory']

    switch (`/app/${location.pathname.split('/')[2]}`) {
      case '/app/contact':
        navTabReload(listNavTab, listContainerAside, 'nav-tab-contacts', 'nav-tab-content-contacts')
        break;
      case '/app/treasury':
        navTabReload(listNavTab, listContainerAside, 'nav-tab-treasury', 'nav-tab-content-treasury')
        break;
      case '/app/sale':
        navTabReload(listNavTab, listContainerAside, 'nav-tab-sales', 'nav-tab-content-sales')
        break;
      case '/app/purchase':
        navTabReload(listNavTab, listContainerAside, 'nav-tab-purchases', 'nav-tab-content-purchases')
        break;
      case '/app/inventory':
        navTabReload(listNavTab, listContainerAside, 'nav-tab-inventory', 'nav-tab-content-inventory')
        break;
      default:
        navTabReload(listNavTab, listContainerAside, 'nav-tab-dashboard', 'nav-tab-content-dashborad')
        break;
    }
  }, [location])

  return (
    <div id="navbar-aside" className="aside aside-extended" data-kt-drawer="true">
      <div id="container-aside-primary" className="aside-primary d-flex flex-column align-items-lg-center flex-row-auto">
        <div className="aside-logo d-none d-lg-flex flex-column align-items-center flex-column-auto py-10">
          <div className="cursor-pointer">
            <img src={logo} alt={lang.labels.digitalAdmin} className="h-25px" />
          </div>
        </div>
        <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid w-100 pt-5 pt-lg-0">
          <div id="container-aside-primary-scroll" className="hover-scroll-overlay-y mb-10">
            <ul className="nav flex-column">
              <li className="nav-item mb-2">
                <a id="nav-tab-companies" href="#nav-tab-content-companies" className="nav-link btn btn-custom btn-icon btn-icon-menu d-flex flex-column flex-center" data-bs-toggle="tab" onClick={(event) => executeClickOnTab(event.currentTarget.id)}>
                  <span className="svg-icon svg-icon-2x">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3" d="M20 15H4C2.9 15 2 14.1 2 13V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V13C22 14.1 21.1 15 20 15ZM13 12H11C10.5 12 10 12.4 10 13V16C10 16.5 10.4 17 11 17H13C13.6 17 14 16.6 14 16V13C14 12.4 13.6 12 13 12Z" fill="black"/>
                      <path d="M14 6V5H10V6H8V5C8 3.9 8.9 3 10 3H14C15.1 3 16 3.9 16 5V6H14ZM20 15H14V16C14 16.6 13.5 17 13 17H11C10.5 17 10 16.6 10 16V15H4C3.6 15 3.3 14.9 3 14.7V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V14.7C20.7 14.9 20.4 15 20 15Z" fill="black"/>
                    </svg>
                  </span>
                  <span className="text-muted text-uppercase">{lang.labels.companies}</span>
                </a>
              </li>
              <li className="nav-item mb-2">
                <a id="nav-tab-dashboard" href="#nav-tab-content-dashborad" className="nav-link btn btn-custom btn-icon btn-icon-menu d-flex flex-column flex-center" data-bs-toggle="tab" onClick={(event) => executeClickOnTab(event.currentTarget.id)}>
                  <span className="svg-icon svg-icon-2x">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="2" y="2" width="9" height="9" rx="2" fill="black" />
                      <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="black" />
                      <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="black" />
                      <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="black" />
                    </svg>
                  </span>
                  <span className="text-muted text-uppercase">{lang.labels.dashboard}</span>
                </a>
              </li>
              <li className="nav-item mb-2">
                <a id="nav-tab-contacts" href="#nav-tab-content-contacts" className="nav-link btn btn-custom btn-icon btn-icon-menu d-flex flex-column flex-center" data-bs-toggle="tab" onClick={(event) => executeClickOnTab(event.currentTarget.id)}>
                  <span className="svg-icon svg-icon-2x">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z" fill="black"/>
                      <path opacity="0.3" d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z" fill="black"/>
                    </svg>
                  </span>
                  <span className="text-muted text-uppercase">{lang.labels.contacts}</span>
                </a>
              </li>
              <li className="nav-item mb-2">
                <a id="nav-tab-treasury" href="#nav-tab-content-treasury" className="nav-link btn btn-custom btn-icon btn-icon-menu d-flex flex-column flex-center" data-bs-toggle="tab" onClick={(event) => executeClickOnTab(event.currentTarget.id)}>
                  <span className="svg-icon svg-icon-2x">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z" fill="black"/>
                      <path opacity="0.3" d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z" fill="black"/>
                    </svg>
                  </span>
                  <span className="text-muted text-uppercase">{lang.labels.treasury}</span>
                </a>
              </li>
              <li className="nav-item mb-2">
                <a id="nav-tab-sales" href="#nav-tab-content-sales" className="nav-link btn btn-custom btn-icon btn-icon-menu d-flex flex-column flex-center" data-bs-toggle="tab" onClick={(event) => executeClickOnTab(event.currentTarget.id)}>
                  <span className="svg-icon svg-icon-2x">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3" d="M11 11H13C13.6 11 14 11.4 14 12V21H10V12C10 11.4 10.4 11 11 11ZM16 3V21H20V3C20 2.4 19.6 2 19 2H17C16.4 2 16 2.4 16 3Z" fill="black"/>
                      <path d="M21 20H8V16C8 15.4 7.6 15 7 15H5C4.4 15 4 15.4 4 16V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z" fill="black"/>
                    </svg>
                  </span>
                  <span className="text-muted text-uppercase">{lang.labels.sales}</span>
                </a>
              </li>
              <li className="nav-item mb-2">
                <a id="nav-tab-purchases" href="#nav-tab-content-purchases" className="nav-link btn btn-custom btn-icon btn-icon-menu d-flex flex-column flex-center" data-bs-toggle="tab" onClick={(event) => executeClickOnTab(event.currentTarget.id)}>
                  <span className="svg-icon svg-icon-2x">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3" d="M14 12V21H10V12C10 11.4 10.4 11 11 11H13C13.6 11 14 11.4 14 12ZM7 2H5C4.4 2 4 2.4 4 3V21H8V3C8 2.4 7.6 2 7 2Z" fill="black"/>
                      <path d="M21 20H20V16C20 15.4 19.6 15 19 15H17C16.4 15 16 15.4 16 16V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z" fill="black"/>
                    </svg>
                  </span>
                  <span className="text-muted text-uppercase">{lang.labels.purchases}</span>
                </a>
              </li>
              <li className="nav-item mb-2">
                <a id="nav-tab-inventory" href="#nav-tab-content-inventory" className="nav-link btn btn-custom btn-icon btn-icon-menu d-flex flex-column flex-center" data-bs-toggle="tab" onClick={(event) => executeClickOnTab(event.currentTarget.id)}>
                  <span className="svg-icon svg-icon-2x">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.3" d="M5 8.04999L11.8 11.95V19.85L5 15.85V8.04999Z" fill="black"/>
                      <path d="M20.1 6.65L12.3 2.15C12 1.95 11.6 1.95 11.3 2.15L3.5 6.65C3.2 6.85 3 7.15 3 7.45V16.45C3 16.75 3.2 17.15 3.5 17.25L11.3 21.75C11.5 21.85 11.6 21.85 11.8 21.85C12 21.85 12.1 21.85 12.3 21.75L20.1 17.25C20.4 17.05 20.6 16.75 20.6 16.45V7.45C20.6 7.15 20.4 6.75 20.1 6.65ZM5 15.85V7.95L11.8 4.05L18.6 7.95L11.8 11.95V19.85L5 15.85Z" fill="black"/>
                    </svg>
                  </span>
                  <span className="text-muted text-uppercase">{lang.labels.inventory}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        { userLoggedIn &&
          <div className="aside-footer d-flex flex-column align-items-center flex-column-auto" onLoad={uploadTooltip}>
            { userLoggedIn.companies.length > 0 &&
              <div className="d-flex align-items-center mb-2" data-bs-toggle="tooltip" data-bs-placement="right" title={lang.labels.notifications} onClick={removeTooltip}>
                <div id="button-notifications" className="btn btn-icon btn-custom position-relative" onClick={executeNotifications}>
                  { countNotifications() > 0 &&
                    <span className="position-absolute top-25 start-75 translate-middle bg-warning border border-light rounded-circle p-1"></span>
                  }
                  <span className="svg-icon svg-icon-1 svg-icon-lg-2x">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 8.725C6 8.125 6.4 7.725 7 7.725H14L18 11.725V12.925L22 9.725L12.6 2.225C12.2 1.925 11.7 1.925 11.4 2.225L2 9.725L6 12.925V8.725Z" fill="black"/>
                      <path opacity="0.3" d="M22 9.72498V20.725C22 21.325 21.6 21.725 21 21.725H3C2.4 21.725 2 21.325 2 20.725V9.72498L11.4 17.225C11.8 17.525 12.3 17.525 12.6 17.225L22 9.72498ZM15 11.725H18L14 7.72498V10.725C14 11.325 14.4 11.725 15 11.725Z" fill="black"/>
                    </svg>
                  </span>
                </div>
              </div>
            }
            <div className="d-flex align-items-center mb-2 d-none" data-bs-toggle="tooltip" data-bs-placement="right" title={lang.labels.languages} onClick={removeTooltip}>
              <div id="button-languages" className="btn btn-icon btn-custom" onClick={executeLanguages}>
                <span className="svg-icon svg-icon-1 svg-icon-lg-2x">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.3" d="M18.4 5.59998C21.9 9.09998 21.9 14.8 18.4 18.3C14.9 21.8 9.2 21.8 5.7 18.3L18.4 5.59998Z" fill="black"/>
                    <path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM19.9 11H13V8.8999C14.9 8.6999 16.7 8.00005 18.1 6.80005C19.1 8.00005 19.7 9.4 19.9 11ZM11 19.8999C9.7 19.6999 8.39999 19.2 7.39999 18.5C8.49999 17.7 9.7 17.2001 11 17.1001V19.8999ZM5.89999 6.90002C7.39999 8.10002 9.2 8.8 11 9V11.1001H4.10001C4.30001 9.4001 4.89999 8.00002 5.89999 6.90002ZM7.39999 5.5C8.49999 4.7 9.7 4.19998 11 4.09998V7C9.7 6.8 8.39999 6.3 7.39999 5.5ZM13 17.1001C14.3 17.3001 15.6 17.8 16.6 18.5C15.5 19.3 14.3 19.7999 13 19.8999V17.1001ZM13 4.09998C14.3 4.29998 15.6 4.8 16.6 5.5C15.5 6.3 14.3 6.80002 13 6.90002V4.09998ZM4.10001 13H11V15.1001C9.1 15.3001 7.29999 16 5.89999 17.2C4.89999 16 4.30001 14.6 4.10001 13ZM18.1 17.1001C16.6 15.9001 14.8 15.2 13 15V12.8999H19.9C19.7 14.5999 19.1 16.0001 18.1 17.1001Z" fill="black"/>
                  </svg>
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center mb-5" data-bs-toggle="tooltip" data-bs-placement="right" title={lang.labels.userProfile} onClick={removeTooltip}>
              <div id="button-user-profile" className="cursor-pointer symbol symbol-40px" onClick={executeUserProfile}>
                <img src={imageUser(userLoggedIn.avatar)} alt={lang.labels.avatar} />
              </div>
            </div>
          </div>
        }
      </div>
      <div id="container-aside-secondary" className="aside-secondary d-flex flex-row-fluid">
        <div className="aside-workspace p-2 pe-3">
          <div className="d-flex flex-column h-100">
            <div id="container-aside-secondary-scroll" className="flex-column-fluid hover-scroll-y w-100">
              <div className="tab-content">
                <div id="nav-tab-content-companies" className="tab-pane fade" role="tabpanel">
                  <div className="d-flex mb-5">
                    <div id="dropdown-search" className="menu-dropdown d-flex align-items-center w-100 w-lg-400px">
                      <div id="container-search-form" className="w-100 position-relative mb-5 mb-lg-0">
                        <span className="svg-icon svg-icon-2 svg-icon-lg-3 svg-icon-gray-800 position-absolute top-50 translate-middle-y ms-3">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                            <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                          </svg>
                        </span>
                        <input className="form-control form-control-solid px-10" type="text" name="search" placeholder={lang.labels.search} value={searchData.text} onChange={handleChangeSearch} />
                        <span id="icon-spinner" className={`position-absolute top-50 end-0 translate-middle-y lh-0 me-2 ${searchAnimation.spinner === false && "d-none"}`}>
                          <span className="spinner-border h-15px w-15px align-middle text-gray-400"></span>
                        </span>
                        <span id="button-clear" className={`btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 me-2 ${searchAnimation.clear === false && "d-none"}`} onClick={executeClearSearch}>
                          <span className="svg-icon svg-icon-2 svg-icon-lg-1 me-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
                              <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
                            </svg>
                          </span>
                        </span>
                      </div>
                      <div id="container-search" className="menu menu-sub menu-sub-dropdown overflow-hidden p-5 w-300px w-md-350px">
                        <Container property={(searchData.list_app_services.length > 0 || searchData.list_companies.length > 0).toString()}>
                          <div className="d-flex flex-stack fw-bolder mb-5">
                            <span className="text-muted fs-6">{lang.labels.searchResults}:</span>
                          </div>
                          <div className="scroll-y mh-200px mh-lg-325px">
                            { searchData.list_app_services.length > 0 &&
                              <>
                                <div className="d-flex flex-stack fw-bold mb-3">
                                  <span className="text-muted fs-6">{lang.labels.appServices}:</span>
                                </div>
                                { searchData.list_app_services.map (( (item, index) => {
                                  return (
                                    <div key={index}>
                                      <div className="d-flex align-items-center mb-3 cursor-pointer">
                                        <div className="symbol symbol-40px me-3">
                                          <span className="symbol-label bg-light">
                                            <span className="svg-icon svg-icon-2 svg-icon-dark">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="black" />
                                                <path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="black" />
                                              </svg>
                                            </span>
                                          </span>
                                        </div>
                                        <div className="d-flex flex-column">
                                          <div className="fs-7 text-gray-800 text-hover-primary fw-bold">{item.name}</div>
                                          <span className="fs-8 text-muted fw-bold">{item.api_key}</span>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                }))}
                              </>
                            }
                            { searchData.list_companies.length > 0 &&
                              <>
                                <div className="d-flex flex-stack fw-bold mb-3">
                                  <span className="text-muted fs-6">{lang.labels.companies}:</span>
                                </div>
                                { searchData.list_companies.map (( (item, index) => {
                                  return (
                                    <div key={index} onClick={() => submitCompanySelectedInSearch(item)}>
                                      <div className="d-flex align-items-center mb-3 cursor-pointer">
                                        <div className="symbol symbol-40px me-3">
                                          <span className="symbol-label bg-light">
                                            <img src={imageCompany(item.logo)} alt={lang.labels.logo} className="w-50 h-50 align-self-center" />
                                          </span>
                                        </div>
                                        <div className="d-flex flex-column">
                                          <div className="fs-7 text-gray-800 text-hover-primary fw-bold">{item.name}</div>
                                          <span className="fs-8 text-muted fw-bold">{item.address.city}, {countryName(item.address.country, lang.code)}</span>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                }))}
                              </>
                            }
                          </div>
                        </Container>
                        <Container property={(searchData.list_app_services.length === 0 && searchData.list_companies.length === 0).toString()}>
                          <div className="text-center">
                            <div className="py-10">
                              <span className="svg-icon svg-icon-5x opacity-50">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path opacity="0.3" d="M14 2H6C4.89543 2 4 2.89543 4 4V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8L14 2Z" fill="black" />
                                  <path d="M20 8L14 2V6C14 7.10457 14.8954 8 16 8H20Z" fill="black" />
                                  <rect x="13.6993" y="13.6656" width="4.42828" height="1.73089" rx="0.865447" transform="rotate(45 13.6993 13.6656)" fill="black" />
                                  <path d="M15 12C15 14.2 13.2 16 11 16C8.8 16 7 14.2 7 12C7 9.8 8.8 8 11 8C13.2 8 15 9.8 15 12ZM11 9.6C9.68 9.6 8.6 10.68 8.6 12C8.6 13.32 9.68 14.4 11 14.4C12.32 14.4 13.4 13.32 13.4 12C13.4 10.68 12.32 9.6 11 9.6Z" fill="black" />
                                </svg>
                              </span>
                            </div>
                            <div className="pb-10 fw-bold">
                              <h3 className="text-gray-600 fs-5 mb-2">{lang.labels.noResultFound}</h3>
                              <div className="text-muted fs-7">{lang.labels.pleaseTryAgainWithADifferentQuery}</div>
                            </div>
                          </div>
                        </Container>
                      </div>
                    </div>
                  </div>
                  <div className="m-0">
                    <h1 className="text-gray-900 mx-3 mb-5">{lang.labels.companies}</h1>
                    <div className="w-100 h-100">
                      { userLoggedIn && userLoggedIn.companies.map (( (item, index) => {
                        return (
                          <div key={index} onClick={() => submitCompanySelected(item)} className={(item.id === companyForUser?.id) ? "bg-light-primary" : "bg-tranpsarent"}>
                            <div className="custom-list d-flex align-items-center p-3 cursor-pointer" >
                              <div className="symbol symbol-40px me-5">
                                <span className="symbol-label">
                                  <img src={imageCompany(item.logo)} alt={lang.labels.logo} className="w-50 h-50 align-self-center" />
                                </span>
                              </div>
                              <div className="d-flex flex-column flex-grow-1">
                                <h6 className="custom-list-title text-hover-primary text-gray-800 fw-bold mb-1">{item.name}</h6>
                                <small className="text-gray-400 fw-bold">{item.address.city}, {countryName(item.address.country, lang.code)}</small>
                              </div>
                            </div>
                          </div>
                        )
                      }))}
                    </div>
                  </div>
                </div>
                <div id="nav-tab-content-dashborad" className="tab-pane fade" role="tabpanel">
                  <div className="menu menu-column menu-fit menu-rounded menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold fs-6 " data-kt-menu="true">
                    <div className="menu-fit">
                      <div className="menu-item">
                        <div className="menu-content">
                          <span className="menu-section text-muted text-uppercase fs-8 ls-1">{lang.labels.company}</span>
                        </div>
                      </div>
                      <div className="menu-item">
                        <NavLink to="/app/dashboard" className={({isActive}) => `btn btn-link menu-link ${isActive && 'active'}`} onClick={executeNavbarAsideHide}>
                          <span className="menu-icon">
                            <span className="svg-icon svg-icon-2">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect x="2" y="2" width="9" height="9" rx="2" fill="black" />
                                <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="black" />
                                <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="black" />
                                <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="black" />
                              </svg>
                            </span>
                          </span>
                          <span className="menu-title">{lang.labels.dashboard}</span>
                        </NavLink>
                      </div>
                      <div className="menu-item">
                        <div className="menu-content">
                          <div className="separator mx-1 my-0"></div>
                        </div>
                      </div>
                      { userLoggedIn && userLoggedIn.companies.length > 0 &&
                        <>
                          <div className="menu-item">
                            <NavLink to="/app/company/view" className={({isActive}) => `menu-link ${isActive && 'active'}`} onClick={executeNavbarAsideHide}>
                              <span className="menu-icon">
                                <span className="svg-icon svg-icon-2">
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11 2.375L2 9.575V20.575C2 21.175 2.4 21.575 3 21.575H9C9.6 21.575 10 21.175 10 20.575V14.575C10 13.975 10.4 13.575 11 13.575H13C13.6 13.575 14 13.975 14 14.575V20.575C14 21.175 14.4 21.575 15 21.575H21C21.6 21.575 22 21.175 22 20.575V9.575L13 2.375C12.4 1.875 11.6 1.875 11 2.375Z" fill="black"/>
                                  </svg>
                                </span>
                              </span>
                              <span className="menu-title">{lang.labels.overview}</span>
                            </NavLink>
                          </div>
                          <div className="menu-item">
                            <NavLink to="/app/company/update" className={({isActive}) => `menu-link ${isActive && 'active'}`} onClick={executeNavbarAsideHide}>
                              <span className="menu-icon">
                                <span className="svg-icon svg-icon-2">
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z" fill="black"/>
                                    <path d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z" fill="black"/>
                                    <path d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z" fill="black"/>
                                  </svg>
                                </span>
                              </span>
                              <span className="menu-title">{lang.labels.settings}</span>
                            </NavLink>
                          </div>
                          <div className="menu-item">
                            <div className="menu-content">
                              <div className="separator mx-1 my-0"></div>
                            </div>
                          </div>
                          <div id="menu-accordion-1" className="menu-item menu-accordion mb-1 d-none" data-type="menu-accordion">
                            <span className="menu-link" onClick={() => executeOptionAccordion(1)}>
                              <span className="menu-icon">
                                <span className="svg-icon svg-icon-2">
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18 21.6C16.6 20.4 9.1 20.3 6.3 21.2C5.7 21.4 5.1 21.2 4.7 20.8L2 18C4.2 15.8 10.8 15.1 15.8 15.8C16.2 18.3 17 20.5 18 21.6ZM18.8 2.8C18.4 2.4 17.8 2.20001 17.2 2.40001C14.4 3.30001 6.9 3.2 5.5 2C6.8 3.3 7.4 5.5 7.7 7.7C9 7.9 10.3 8 11.7 8C15.8 8 19.8 7.2 21.5 5.5L18.8 2.8Z" fill="black"/>
                                    <path opacity="0.3" d="M21.2 17.3C21.4 17.9 21.2 18.5 20.8 18.9L18 21.6C15.8 19.4 15.1 12.8 15.8 7.8C18.3 7.4 20.4 6.70001 21.5 5.60001C20.4 7.00001 20.2 14.5 21.2 17.3ZM8 11.7C8 9 7.7 4.2 5.5 2L2.8 4.8C2.4 5.2 2.2 5.80001 2.4 6.40001C2.7 7.40001 3.00001 9.2 3.10001 11.7C3.10001 15.5 2.40001 17.6 2.10001 18C3.20001 16.9 5.3 16.2 7.8 15.8C8 14.2 8 12.7 8 11.7Z" fill="black"/>
                                  </svg>
                                </span>
                              </span>
                              <span className="menu-title">{lang.labels.menu}</span>
                              <span className="menu-arrow"></span>
                            </span>
                            <div id="menu-sub-accordion-1" className="menu-sub menu-sub-accordion show" data-type="menu-sub-accordion">
                              <div className="menu-item">
                                <NavLink to="#" className="menu-link">
                                  <span className="menu-bullet">
                                    <span className="bullet bullet-dot"></span>
                                  </span>
                                  <span className="menu-title">Option 1</span>
                                </NavLink>
                              </div>
                              <div className="menu-item">
                                <NavLink to="#" className="menu-link">
                                  <span className="menu-bullet">
                                    <span className="bullet bullet-dot"></span>
                                  </span>
                                  <span className="menu-title">Option 2</span>
                                </NavLink>
                              </div>
                            </div>
                          </div>
                          <div id="menu-accordion-2" className="menu-item menu-accordion mb-1 d-none" data-type="menu-accordion">
                            <span className="menu-link" onClick={() => executeOptionAccordion(2)}>
                              <span className="menu-icon">
                                <span className="svg-icon svg-icon-2">
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="currentColor"></path>
                                    <path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="currentColor"></path>
                                  </svg>
                                </span>
                              </span>
                              <span className="menu-title">{lang.labels.pages}</span>
                              <span className="menu-arrow"></span>
                            </span>
                            <div id="menu-sub-accordion-2" className="menu-sub menu-sub-accordion" data-type="menu-sub-accordion">
                              <div id="menu-accordion-pages-1" className="menu-item menu-accordion mb-1" data-type="menu-accordion-pages">
                                <span className="menu-link" onClick={() => executeOptionAccordionPages(1)}>
                                  <span className="menu-bullet">
                                    <span className="bullet bullet-dot"></span>
                                  </span>
                                  <span className="menu-title">Page 1</span>
                                  <span className="menu-arrow"></span>
                                </span>
                                <div id="menu-sub-accordion-pages-1" className="menu-sub menu-sub-accordion" data-type="menu-sub-accordion-pages">
                                  <div className="menu-item">
                                    <NavLink to="#" className="menu-link">
                                      <span className="menu-bullet">
                                        <span className="bullet bullet-dot"></span>
                                      </span>
                                      <span className="menu-title">Option 1</span>
                                    </NavLink>
                                  </div>
                                  <div className="menu-item">
                                    <NavLink to="#" className="menu-link">
                                      <span className="menu-bullet">
                                        <span className="bullet bullet-dot"></span>
                                      </span>
                                      <span className="menu-title">Option 2</span>
                                    </NavLink>
                                  </div>
                                </div>
                              </div>
                              <div id="menu-accordion-pages-2" className="menu-item menu-accordion mb-1" data-type="menu-accordion-pages">
                                <span className="menu-link" onClick={() => executeOptionAccordionPages(2)}>
                                  <span className="menu-bullet">
                                    <span className="bullet bullet-dot"></span>
                                  </span>
                                  <span className="menu-title">Page 2</span>
                                  <span className="menu-arrow"></span>
                                </span>
                                <div id="menu-sub-accordion-pages-2" className="menu-sub menu-sub-accordion" data-type="menu-sub-accordion-pages">
                                  <div className="menu-item">
                                    <NavLink to="#" className="menu-link">
                                      <span className="menu-bullet">
                                        <span className="bullet bullet-dot"></span>
                                      </span>
                                      <span className="menu-title">Option 1</span>
                                    </NavLink>
                                  </div>
                                  <div className="menu-item">
                                    <NavLink to="#" className="menu-link">
                                      <span className="menu-bullet">
                                        <span className="bullet bullet-dot"></span>
                                      </span>
                                      <span className="menu-title">Option 2</span>
                                    </NavLink>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </div>
                <div id="nav-tab-content-contacts" className="tab-pane fade" role="tabpanel">
                  <div className="text-center pt-5 mb-10">
                    <h2 className="fs-2 mb-5">{lang.labels.contacts}</h2>
                    <p className="text-gray-400 fs-6 fw-bold mb-10">
                      {lang.labels.selectOneOption}
                      <br/>
                      {lang.labels.youWantToRun}
                    </p>
                    <div className="w-100">
                      <NavLink to="/app/contact/list" className={({isActive}) => `btn btn-primary w-100 my-1 ${isActive && 'active'}`} onClick={executeNavbarAsideHide}>{lang.labels.viewContactsList}</NavLink>
                      <NavLink to="/app/contact/create" className={({isActive}) => `btn btn-primary w-100 my-1 ${isActive && 'active'}`} onClick={executeNavbarAsideHide}>{lang.labels.createNewContact}</NavLink>
                    </div>
                  </div>
                  <div className="text-center px-5">
                    <img src={imgContacts} className="mw-100 mh-200px" alt={lang.labels.contacts} />
                  </div>
                </div>
                <div id="nav-tab-content-treasury" className="tab-pane fade" role="tabpanel">
                  <div className="text-center pt-5 mb-10">
                    <h2 className="fs-2 mb-5">{lang.labels.treasuryAccounts}</h2>
                    <p className="text-gray-400 fs-6 fw-bold mb-10">
                      {lang.labels.selectOneOption}
                      <br/>
                      {lang.labels.youWantToRun}
                    </p>
                    <div className="w-100">
                      <NavLink to="/app/treasury/list" className={({isActive}) => `btn btn-primary w-100 my-1 ${isActive && 'active'}`} onClick={executeNavbarAsideHide}>{lang.labels.viewTreasuryAccountsList}</NavLink>
                      <NavLink to="/app/treasury/create" className={({isActive}) => `btn btn-primary w-100 my-1 ${isActive && 'active'}`} onClick={executeNavbarAsideHide}>{lang.labels.createNewTreasuryAccount}</NavLink>
                    </div>
                  </div>
                  <div className="text-center px-5">
                    <img src={imgTreasury} className="mw-100 mh-200px" alt={lang.labels.treasury} />
                  </div>
                </div>
                <div id="nav-tab-content-sales" className="tab-pane fad" role="tabpanel">
                  <div className="text-center pt-5 mb-10">
                    <h2 className="fs-2 mb-5">{lang.labels.sales}</h2>
                    <p className="text-gray-400 fs-6 fw-bold mb-10">
                    {lang.labels.selectOneOption}
                    <br/>
                    {lang.labels.youWantToRun}
                  </p>
                    <div className="w-100">
                      <NavLink to="/app/sale/quote/list" className={({isActive}) => `btn btn-primary w-100 my-1 ${isActive && 'active'}`} onClick={executeNavbarAsideHide}>{lang.labels.viewQuotes}</NavLink>
                      <NavLink to="/app/sale/order/list" className={({isActive}) => `btn btn-primary w-100 my-1 ${isActive && 'active'}`} onClick={executeNavbarAsideHide}>{lang.labels.viewOrders}</NavLink>
                      <NavLink to="/app/sale/invoice/list" className={({isActive}) => `btn btn-primary w-100 my-1 ${isActive && 'active'}`} onClick={executeNavbarAsideHide}>{lang.labels.viewInvoices}</NavLink>
                    </div>
                  </div>
                  <div className="text-center px-5">
                    <img src={imgSales} alt={lang.labels.sales} className="mw-100 mh-200px" />
                  </div>
                </div>
                <div id="nav-tab-content-purchases" className="tab-pane fad" role="tabpanel">
                  <div className="text-center pt-5 mb-10">
                    <h2 className="fs-2 mb-5">{lang.labels.purchases}</h2>
                    <p className="text-gray-400 fs-6 fw-bold mb-10">
                    {lang.labels.selectOneOption}
                    <br/>
                    {lang.labels.youWantToRun}
                  </p>
                    <div className="w-100">
                      <NavLink to="/app/purchase/order/list" className={({isActive}) => `btn btn-primary w-100 my-1 ${isActive && 'active'}`} onClick={executeNavbarAsideHide}>{lang.labels.viewProviderOrders}</NavLink>
                      <NavLink to="/app/purchase/invoice/list" className={({isActive}) => `btn btn-primary w-100 my-1 ${isActive && 'active'}`} onClick={executeNavbarAsideHide}>{lang.labels.viewProviderInvoices}</NavLink>
                      <NavLink to="/app/purchase/expense/list" className={({isActive}) => `btn btn-primary w-100 my-1 ${isActive && 'active'}`} onClick={executeNavbarAsideHide}>{lang.labels.viewExpenses}</NavLink>
                    </div>
                  </div>
                  <div className="text-center px-5">
                    <img src={imgPurchases} alt={lang.labels.purchases} className="mw-100 mh-200px" />
                  </div>
                </div>
                <div id="nav-tab-content-inventory" className="tab-pane fade"  role="tabpanel">
                  <div className="text-center pt-5 mb-10">
                    <h2 className="fs-2 mb-5">{lang.labels.inventory}</h2>
                    <p className="text-gray-400 fs-6 fw-bold mb-10">
                    {lang.labels.selectOneOption}
                    <br/>
                    {lang.labels.youWantToRun}
                  </p>
                    <div className="w-100">
                      <NavLink to="/app/inventory/category/list" className={({isActive}) => `btn btn-primary w-100 my-1 ${isActive && 'active'}`} onClick={executeNavbarAsideHide}>{lang.labels.viewCategories}</NavLink>
                      <NavLink to="/app/inventory/service/list" className={({isActive}) => `btn btn-primary w-100 my-1 ${isActive && 'active'}`} onClick={executeNavbarAsideHide}>{lang.labels.viewServices}</NavLink>
                      <NavLink to="/app/inventory/product/list" className={({isActive}) => `btn btn-primary w-100 my-1 ${isActive && 'active'}`} onClick={executeNavbarAsideHide}>{lang.labels.viewProducts}</NavLink>
                      <NavLink to="/coming/soon" className={({isActive}) => `btn btn-primary w-100 my-1 ${isActive && 'active'}`} onClick={executeNavbarAsideHide}>{lang.labels.viewStorageCenter}</NavLink>
                    </div>
                  </div>
                  <div className="text-center px-5">
                    <img src={imgInventory} alt={lang.labels.contacts} className="mw-100 mh-200px" />
                  </div>
                </div>
              </div>
            </div>
            <Footer property={footerNavbar.toString()}>
              <div className="flex-column-auto py-3 px-5">
                <Link to="/app/company/create" className="btn btn-bg-secondary btn-color-gray-900 btn-flex btn-active-color-primary flex-center w-100">
                  <span className="btn-label fw-bold">{lang.labels.createAndSave}</span>
                  <span className="svg-icon btn-icon svg-icon-4 ms-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM15 17C15 16.4 14.6 16 14 16H8C7.4 16 7 16.4 7 17C7 17.6 7.4 18 8 18H14C14.6 18 15 17.6 15 17ZM17 12C17 11.4 16.6 11 16 11H8C7.4 11 7 11.4 7 12C7 12.6 7.4 13 8 13H16C16.6 13 17 12.6 17 12ZM17 7C17 6.4 16.6 6 16 6H8C7.4 6 7 6.4 7 7C7 7.6 7.4 8 8 8H16C16.6 8 17 7.6 17 7Z" fill="black" />
                      <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black" />
                    </svg>
                  </span>
                </Link>
              </div>
            </Footer>
          </div>
        </div>
      </div>
      <button id="button-navbar-aside-minimize" className="btn btn-sm btn-icon bg-body btn-color-gray-600 btn-active-primary position-absolute translate-middle start-100 end-0 bottom-0 shadow-sm d-none d-lg-flex mb-13" type="button" onClick={executeNavbarAsideMinimize}>
        <span className="svg-icon svg-icon-2 rotate-180">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="black" />
            <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="black" />
          </svg>
        </span>
      </button>
      <div id="overlay-menu-transparent" className="" onClick={executeOverlayTransparent}></div>
    </div>
  )
}

export default AppNavbar;
