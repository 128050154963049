import { Language } from "../languages/labels/language.labels";

export function requirementTypeList(lang: Language): {code: string, description: string}[] {
  return [
    { code: 'service', description: lang.labels.service },
    { code: 'product', description: lang.labels.product }
  ]
}

export function requirementTypeValue(lang: Language, code: string): string {
  const requirementType = requirementTypeList(lang).find(item => item.code === code)
  return requirementType ? requirementType.description : lang.labels.undefined
}
