import React, { Dispatch, SetStateAction } from 'react';
import TextareaAutosize from 'react-textarea-autosize';

import { StateLanguage } from '../../../../../languages/config/StateLanguage';
import { Category } from '../../../../../models/category.model';
import { legendInvalidIcon, legendValidIcon, legendValidInvalidIconRestart } from '../../../../../tools/legend.data.entry.tool';
import { evaluateLegendValidateRequired, evaluateLegendValidateRequiredIcon } from '../../../../../scripts/validate.legend.script';
import { languageDownloadValue } from '../../../../../libraries/language.download.library';
import { expressions } from '../../../../../libraries/regular.expressions.library';

import AppLegend from '../../../../../components/element/Legend';

export interface AppCategoryFormProps {
  component: string,
  category: Category | null,
  name: {value: string, valid: boolean},
  description: {value: string, valid: boolean},
  names: {value: {en: string, fr: string, it: string, de: string}, valid: {en: boolean, fr: boolean, it: boolean, de: boolean}},
  setName: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setDescription: Dispatch<SetStateAction<{value: string, valid: boolean}>>,
  setNames: Dispatch<SetStateAction<{value: {en: string, fr: string, it: string, de: string}, valid: {en: boolean, fr: boolean, it: boolean, de: boolean}}>>
};

const AppCategoryForm: React.FunctionComponent<AppCategoryFormProps> = ({component, category, name, description, names, setName, setDescription, setNames}) => {
  const {lang} = StateLanguage()

  const handleChangeName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setName({...name, value: event.target.value})
  }

  const handleChangeDescription = (event: React.ChangeEvent <HTMLFormElement | HTMLTextAreaElement>) => {
    setDescription({...description, value: event.target.value})
  }

  const handleChangeNames = (item: string, event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setNames(names => ({...names, value: {...names.value, [item]: event.target.value}}))
  }

  const validateName = () => {
    evaluateLegendValidateRequiredIcon(expressions.name, name, setName, component + '-input-name', component + '-container-validate-name-valid', component + '-container-validate-name-required')
  }

  const validateDescription = () => {
    evaluateLegendValidateRequired(expressions.text, description, setDescription, component + '-container-validate-description-valid', component + '-container-validate-description-required')
  }

  const validateNames = (index: number, item: string) => {
    if (expressions && names.value[item as keyof typeof names.value].length > 0) {
      if (expressions.name.test(names.value[item as keyof typeof names.value])) {
        setNames(names => ({...names, valid: {...names.valid, [item]: true}}))
        legendValidIcon(component + '-input-language-name-' + index, component + '-container-validate-language-name-valid-' + index)
      } else {
        setNames(names => ({...names, valid: {...names.valid, [item]: false}}))
        legendInvalidIcon(component + '-input-language-name-' + index, component + '-container-validate-language-name-valid-' + index)
      }
    } else {
      setNames(names => ({...names, valid: {...names.valid, [item]: true}}))
      legendValidInvalidIconRestart(component + '-input-language-name-' + index, component + '-container-validate-language-name-valid-' + index)
    }
  }

  return (
    <div className="form d-flex flex-column flex-lg-row">
      <div className="d-flex flex-column w-100 w-lg-300px order-2 order-lg-1">
        <div className="card card-flush">
          <div className="card-header">
            <div className="card-title">
              <h3>{lang.labels.NameInOtherLanguages}</h3>
            </div>
          </div>
          <div className="card-body pt-0">
            <label className="form-label">{lang.labels.enterInLanguage}</label>
            { Object.entries(names.value).map (( (item, index) => { return (
              <div key={index} className="my-2">
                <input id={component + "-input-language-name-" + index} className="form-control" type="text" name="language-name" autoComplete="off" placeholder={languageDownloadValue(lang, item[0].toUpperCase())} value={item[1]} onChange={(event) => handleChangeNames(item[0], event)} onKeyUp={() => validateNames(index, item[0])} onBlur={() => validateNames(index, item[0])} />
                <AppLegend component={component} attribute={{validity: names.valid[item[0] as keyof typeof names.valid], name: "language-name", index: index, sub_index: null}} container={{valid: true, required: false, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
              </div>
            )}))}
            { !category &&
              <div className="form-text text-justify mt-5">{lang.labels.ifYouWishYouCanAssignNameOfCategoryInLanguagesNecessary}</div>
            }
          </div>
        </div>
      </div>
      <div className="d-flex flex-column flex-row-fluid order-1 order-lg-2">
        <div className="card card-flush">
          <div className="card-header">
            <div className="card-title">
              <h3>{lang.labels.general}</h3>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="fv-row mb-3">
              <label className="form-label required">{lang.labels.categoryName}</label>
              <input id={component + "-input-name"} className="form-control form-control-solid" type="text" name="name" value={name.value} onChange={handleChangeName} onKeyUp={validateName} onBlur={validateName} />
              <AppLegend component={component} attribute={{validity: name.valid, name: "name", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
            </div>
            <div>
              <label className="required form-label">{lang.labels.description}</label>
              <TextareaAutosize className="form-control form-control-solid" minRows={5} maxRows={7} name="description" autoComplete="off" value={description.value} onChange={handleChangeDescription} onKeyUp={validateDescription} onBlur={validateDescription} />
              <AppLegend component={component} attribute={{validity: description.valid, name: "description", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppCategoryForm;
