import React, { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Form } from 'react-bootstrap';
import { format } from 'date-fns';
import Swal from 'sweetalert2';

import { UseOutletContextSale } from '../Sale';
import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { Quote } from '../../../../models/quote.model';
import { Order } from '../../../../models/order.model';
import { Invoice } from '../../../../models/invoice.model';
import { QuoteGroup } from '../../../../models/quote.group.model';
import { OrderGroup } from '../../../../models/order.group.model';
import { InvoiceGroup } from '../../../../models/invoice.group.model';
import { modalShow } from '../../../../tools/modal.tool';
import { statusInvoiceList, statusOrderList, statusQuoteList, statusRecordValue } from '../../../../libraries/status.record.library';
import { validationList, validationValue } from '../../../../libraries/validation.library';
import { paymentStatusList, paymentStatusValue } from '../../../../libraries/payment.library';
import { expirationList, expirationValue } from '../../../../libraries/expiration.library';
import { dateFormatAbbreviated } from '../../../../scripts/datetime.script';
import { contactSelected, treasuryAccountSelected } from '../../../../scripts/selected.item.list.script';

import AppModalQuoteExport from '../../../../components/modal/ModalQuoteExport';
import AppModalOrderExport from '../../../../components/modal/ModalOrderExport';
import AppModalInvoiceExport from '../../../../components/modal/ModalInvoiceExport';
import AppRemove from '../../../../components/element/Remove';

export interface AppSaleRecordListProps {
  sale: string,
  storage: any,
  button: {filter: boolean, group: boolean},
  filter: {interval: string, start_date: string, end_date: string, contact: string, treasury_account: string, status: string, expiration: string, validity: string, payment: string, sort_field: string, sort_mode: string, group: string},
  records: any[] | Quote[] | Order[] | Invoice[],
  recordGroups: any[] | QuoteGroup[] | OrderGroup[] | InvoiceGroup[],
  setFilter: Dispatch<SetStateAction<{interval: string, start_date: string, end_date: string, contact: string, treasury_account: string, status: string, expiration: string, validity: string, payment: string, sort_field: string, sort_mode: string, group: string}>>,
  loadSaleRecord: Function
};

const AppSaleRecordList: React.FunctionComponent<AppSaleRecordListProps> = ({sale, storage, button, filter, records, recordGroups, setFilter, loadSaleRecord}) => {
  const {companyForUser, contactsForCompany, treasuryAccountsForCompany} = UseOutletContextSale()
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const labels: {[key: string]: {title: string, add: string, status: string, expiration: string, validity: string}} = {
    quote: {title: (button.group) ? lang.labels.quoteGroups : lang.labels.listOfQuotes, add: lang.labels.addQuote, status: lang.labels.quoteStatus, expiration: lang.labels.quoteExpiration, validity: lang.labels.quoteValidity},
    order: {title: (button.group) ? lang.labels.orderGroups : lang.labels.listOfOrders, add: lang.labels.addOrder, status: lang.labels.orderStatus, expiration: lang.labels.orderExpiration, validity: lang.labels.orderValidity},
    invoice: {title: (button.group) ? lang.labels.invoiceGroups : lang.labels.listOfInvoices, add: lang.labels.addInvoice, status: lang.labels.invoiceStatus, expiration: lang.labels.invoiceExpiration, validity: lang.labels.invoiceValidity}
  }

  const handleChangeInterval = (event: React.ChangeEvent <HTMLFormElement | HTMLSelectElement>) => {
    let dateCurrent: string = format((new Date()), 'yyyy-MM-dd')

    switch (event.target.value) {
      case 'today':
        setFilter({...filter, interval: event.target.value, start_date: format((new Date(`${dateCurrent}T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})
        break;
      case 'yesterday':
        let yesterday: string = format(new Date((new Date()).setDate((new Date()).getDate() - 1)), 'yyyy-MM-dd')
        setFilter({...filter, interval: event.target.value, start_date: format((new Date(`${yesterday}T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${yesterday}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})
        break;
      case 'week':
        let weekCurrent: string = format(new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate() - (new Date()).getDay()), 'yyyy-MM-dd')
        setFilter({...filter, interval: event.target.value, start_date: format((new Date(`${weekCurrent}T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})
        break;
      case 'month':
        let monthCurrent: string = format(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1), 'yyyy-MM-dd')
        setFilter({...filter, interval: event.target.value, start_date: format((new Date(`${monthCurrent}T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})
        break;
      case 'bimester':
        let bimesterCurrent: number = Math.floor((new Date()).getMonth()/ 2) + 1
        if (bimesterCurrent === 6) { setFilter({...filter, interval: event.target.value, start_date: format((new Date(`${dateCurrent.split('-')[0]}-11-01T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})}
        if (bimesterCurrent === 5) { setFilter({...filter, interval: event.target.value, start_date: format((new Date(`${dateCurrent.split('-')[0]}-09-01T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})}
        if (bimesterCurrent === 4) { setFilter({...filter, interval: event.target.value, start_date: format((new Date(`${dateCurrent.split('-')[0]}-07-01T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})}
        if (bimesterCurrent === 3) { setFilter({...filter, interval: event.target.value, start_date: format((new Date(`${dateCurrent.split('-')[0]}-05-01T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})}
        if (bimesterCurrent === 2) { setFilter({...filter, interval: event.target.value, start_date: format((new Date(`${dateCurrent.split('-')[0]}-03-01T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})}
        if (bimesterCurrent === 1) { setFilter({...filter, interval: event.target.value, start_date: format((new Date(`${dateCurrent.split('-')[0]}-01-01T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})}
        break;
      case 'trimester':
        let trimesterCurrent: number = Math.floor((new Date()).getMonth()/ 3) + 1
        if (trimesterCurrent === 4) { setFilter({...filter, interval: event.target.value, start_date: format((new Date(`${dateCurrent.split('-')[0]}-10-01T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})}
        if (trimesterCurrent === 3) { setFilter({...filter, interval: event.target.value, start_date: format((new Date(`${dateCurrent.split('-')[0]}-07-01T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})}
        if (trimesterCurrent === 2) { setFilter({...filter, interval: event.target.value, start_date: format((new Date(`${dateCurrent.split('-')[0]}-04-01T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})}
        if (trimesterCurrent === 1) { setFilter({...filter, interval: event.target.value, start_date: format((new Date(`${dateCurrent.split('-')[0]}-01-01T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})}
        break;
      case 'half':
        let halfCurrent: number = Math.floor((new Date()).getMonth()/ 6) + 1
        if (halfCurrent === 2) { setFilter({...filter, interval: event.target.value, start_date: format((new Date(`${dateCurrent.split('-')[0]}-07-01T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})}
        if (halfCurrent === 1) { setFilter({...filter, interval: event.target.value, start_date: format((new Date(`${dateCurrent.split('-')[0]}-01-01T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})}
        break;
      case 'year':
        setFilter({...filter, interval: event.target.value, start_date: format((new Date(`${dateCurrent.split('-')[0]}-01-01T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`), end_date: format((new Date(`${dateCurrent}T23:59:59.999`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`)})
        break;
      default:
        setFilter({...filter, interval: '', start_date: '', end_date: ''})
        break;
    }
  }

  const handleChangeStartDate = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let date: string = (event.target.value.length > 0) ? format((new Date(`${event.target.value}T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`) : ''
    setFilter({...filter, interval: '', start_date: date})
  }

  const handleChangeEndDate = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let date: string = (event.target.value.length > 0) ? format((new Date(`${event.target.value}T00:00:00.000`)), `yyyy-MM-dd'T'HH:mm:ss.SSS`) : ''
    setFilter({...filter, interval: '', end_date: date})
  }

  const handleChangeContact = (event: React.ChangeEvent <HTMLFormElement | HTMLSelectElement>) => {
    setFilter({...filter, contact: event.target.value})
  }

  const handleChangeTreasury = (event: React.ChangeEvent <HTMLFormElement | HTMLSelectElement>) => {
    setFilter({...filter, treasury_account: event.target.value})
  }

  const handleChangeStatus = (event: React.ChangeEvent <HTMLFormElement | HTMLSelectElement>) => {
    setFilter({...filter, status: event.target.value})
  }

  const handleChangeExpiration = (event: React.ChangeEvent <HTMLFormElement | HTMLSelectElement>) => {
    setFilter({...filter, expiration: event.target.value})
  }

  const handleChangeValidity = (event: React.ChangeEvent <HTMLFormElement | HTMLSelectElement>) => {
    setFilter({...filter, validity: event.target.value})
  }

  const handleChangePayment = (event: React.ChangeEvent <HTMLFormElement | HTMLSelectElement>) => {
    setFilter({...filter, payment: event.target.value})
  }

  const executeGroupList = (item: string) => {
    if (companyForUser) {
      filter.group = item
      filter.interval = ''
      filter.start_date = ''
      filter.end_date= ''
      filter.contact = ''
      filter.treasury_account = ''
      filter.status = ''
      filter.expiration = ''
      filter.validity = ''
      filter.payment = ''
      filter.sort_field = ''
      filter.sort_mode = ''
      loadSaleRecord(companyForUser.id)
    }
  }

  const executeFilterRemove = (item: string) => {
    if (companyForUser && (filter.interval.length !== 0 || filter.start_date.length !== 0 || filter.end_date.length !== 0 || filter.contact.length !== 0 || filter.treasury_account.length !== 0 || filter.status.length !== 0 || filter.expiration.length !== 0 || filter.validity.length !== 0 || filter.payment.length !== 0)) {
      for (let key of Object.keys(filter) as Array<keyof typeof filter>) {
        if (key === item) {
          filter[key] = ''
        }
        if (item === "start_date") {
          filter.interval = ''
        }
        if (item === "end_date") {
          filter.interval = ''
        }
        if (item === "interval") {
          filter.start_date = ''
          filter.end_date = ''
        }
      }
      loadSaleRecord(companyForUser.id)
    }
  }

  const executeFilterReset = () => {
    if (companyForUser && (filter.interval.length !== 0 || filter.start_date.length !== 0 || filter.end_date.length !== 0 || filter.contact.length !== 0 || filter.treasury_account.length !== 0 || filter.status.length !== 0 || filter.expiration.length !== 0 || filter.validity.length !== 0 || filter.payment.length !== 0)) {
      filter.interval = ''
      filter.start_date = ''
      filter.end_date= ''
      filter.contact = ''
      filter.treasury_account = ''
      filter.status = ''
      filter.expiration = ''
      filter.validity = ''
      filter.payment = ''
      filter.sort_field = ''
      filter.sort_mode = ''
      loadSaleRecord(companyForUser.id)
    }
  }

  const executeFilterApply = () => {
    if (companyForUser && (filter.interval.length !== 0 || filter.start_date.length !== 0 || filter.end_date.length !== 0 || filter.contact.length !== 0 || filter.treasury_account.length !== 0 || filter.status.length !== 0 || filter.expiration.length !== 0 || filter.validity.length !== 0 || filter.payment.length !== 0)) {
      filter.sort_field = ''
      filter.sort_mode = ''
      filter.group = ''
      loadSaleRecord(companyForUser.id)
    }
  }

  const executeNavigateCreate = () => {
    if (treasuryAccountsForCompany && treasuryAccountsForCompany.length > 0) {
      navigate(`/app/sale/${sale}/create`)
    } else {
      Swal.fire({
        title: lang.labels.thereAreNoTreasuryAccounts,
        text: lang.labels.toCreateAnDocument,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesCreateTreasuryAccount,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then( (result) => {
        if (result.isConfirmed) {
          navigate('/app/treasury/create')
        }
      })
    }
  }

  const executeShowModalExport = () => {
    modalShow(`modal-${sale}-export`)
  }

  return (
    <>
      { companyForUser && contactsForCompany && treasuryAccountsForCompany && records && recordGroups &&
        <>
          <div className="card-header">
            <div className="card-title">
              <div className="badge badge-lg badge-light-primary">
                <div className="d-flex align-items-center flex-wrap">
                  <span className="svg-icon svg-icon-2 svg-icon-primary me-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path opacity="0.3" d="M14.1 15.013C14.6 16.313 14.5 17.813 13.7 19.113C12.3 21.513 9.29999 22.313 6.89999 20.913C5.29999 20.013 4.39999 18.313 4.39999 16.613C5.09999 17.013 5.99999 17.313 6.89999 17.313C8.39999 17.313 9.69998 16.613 10.7 15.613C11.1 15.713 11.5 15.813 11.9 15.813C12.7 15.813 13.5 15.513 14.1 15.013ZM8.5 12.913C8.5 12.713 8.39999 12.513 8.39999 12.313C8.39999 11.213 8.89998 10.213 9.69998 9.613C9.19998 8.313 9.30001 6.813 10.1 5.513C10.6 4.713 11.2 4.11299 11.9 3.71299C10.4 2.81299 8.49999 2.71299 6.89999 3.71299C4.49999 5.11299 3.70001 8.113 5.10001 10.513C5.80001 11.813 7.1 12.613 8.5 12.913ZM16.9 7.313C15.4 7.313 14.1 8.013 13.1 9.013C14.3 9.413 15.1 10.513 15.3 11.713C16.7 12.013 17.9 12.813 18.7 14.113C19.2 14.913 19.3 15.713 19.3 16.613C20.8 15.713 21.8 14.113 21.8 12.313C21.9 9.513 19.7 7.313 16.9 7.313Z" fill="black" />
                      <path d="M9.69998 9.61307C9.19998 8.31307 9.30001 6.81306 10.1 5.51306C11.5 3.11306 14.5 2.31306 16.9 3.71306C18.5 4.61306 19.4 6.31306 19.4 8.01306C18.7 7.61306 17.8 7.31306 16.9 7.31306C15.4 7.31306 14.1 8.01306 13.1 9.01306C12.7 8.91306 12.3 8.81306 11.9 8.81306C11.1 8.81306 10.3 9.11307 9.69998 9.61307ZM8.5 12.9131C7.1 12.6131 5.90001 11.8131 5.10001 10.5131C4.60001 9.71306 4.5 8.91306 4.5 8.01306C3 8.91306 2 10.5131 2 12.3131C2 15.1131 4.2 17.3131 7 17.3131C8.5 17.3131 9.79999 16.6131 10.8 15.6131C9.49999 15.1131 8.7 14.1131 8.5 12.9131ZM18.7 14.1131C17.9 12.8131 16.7 12.0131 15.3 11.7131C15.3 11.9131 15.4 12.1131 15.4 12.3131C15.4 13.4131 14.9 14.4131 14.1 15.0131C14.6 16.3131 14.5 17.8131 13.7 19.1131C13.2 19.9131 12.6 20.5131 11.9 20.9131C13.4 21.8131 15.3 21.9131 16.9 20.9131C19.3 19.6131 20.1 16.5131 18.7 14.1131Z" fill="black" />
                    </svg>
                  </span>
                  <span>{companyForUser.name}</span>
                  <span className="svg-icon svg-icon-2 svg-icon-primary mx-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="black" />
                    </svg>
                  </span>
                  <span>{labels[sale].title}</span>
                  <span className="svg-icon svg-icon-2 svg-icon-primary mx-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M12.6343 12.5657L8.45001 16.75C8.0358 17.1642 8.0358 17.8358 8.45001 18.25C8.86423 18.6642 9.5358 18.6642 9.95001 18.25L15.4929 12.7071C15.8834 12.3166 15.8834 11.6834 15.4929 11.2929L9.95001 5.75C9.5358 5.33579 8.86423 5.33579 8.45001 5.75C8.0358 6.16421 8.0358 6.83579 8.45001 7.25L12.6343 11.4343C12.9467 11.7467 12.9467 12.2533 12.6343 12.5657Z" fill="black" />
                    </svg>
                  </span>
                  <span>{button.group ? `${recordGroups.length} ${lang.labels.groups}` : `${records.length} ${lang.labels.items}`}</span>
                </div>
              </div>
            </div>
            <div className="card-toolbar">
              <Dropdown>
                <Dropdown.Toggle variant={`btn ${!button.filter ? "btn-light-primary" : "btn-primary"} mb-1 me-2`}>
                  <div className="d-flex align-items-center">
                    <span className="svg-icon svg-icon-2 ms-0">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="black" />
                      </svg>
                    </span>
                    {lang.labels.filter}
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu variant="menu menu-sub menu-sub-dropdown w-300px w-md-325px" align="start">
                  <div className="mt-5 mx-5">
                    <div className="fs-5 text-dark fw-bolder">{lang.labels.filterOptions}</div>
                  </div>
                  <div className="separator border-gray-200"></div>
                  <div className="m-5">
                    <div className="row mb-2">
                      <label className="col-5 col-form-label-sm fw-bold fs-7">{lang.labels.timeInterval}:</label>
                      <div className="col-7">
                        <Form.Select bsPrefix="form-select form-select-sm form-select-solid" placeholder={lang.labels.selectOption} name="filter-interval" value={filter.interval} onChange={handleChangeInterval}>
                          <option value="" className="d-none">{lang.labels.selectOption}</option>
                          <option value="today">{lang.labels.today}</option>
                          <option value="yesterday">{lang.labels.yesterday}</option>
                          <option value="week">{lang.labels.thisWeek}</option>
                          <option value="month">{lang.labels.thisMonth}</option>
                          <option value="bimester">{lang.labels.thisBimester}</option>
                          <option value="trimester">{lang.labels.thisTrimester}</option>
                          <option value="half">{lang.labels.thisHalfYear}</option>
                          <option value="year">{lang.labels.thisYear}</option>
                        </Form.Select>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col-md-6">
                        <label className="col-5 col-md-12 form-label fs-7">{lang.labels.startDate}:</label>
                        <input className="col-7 col-md-12 small-input rounded p-1 my-1 my-md-0" type="date" name="filter-start-date" max={filter.end_date.substring(0, 10)} value={filter.start_date.substring(0, 10)} onChange={handleChangeStartDate} />
                      </div>
                      <div className="col-md-6">
                        <label className="col-5 col-md-12 form-label fs-7">{lang.labels.endDate}:</label>
                        <input className="col-7 col-md-12 small-input rounded p-1 my-1 my-md-0" type="date" name="filter-end-date" min={filter.start_date.substring(0, 10)} value={filter.end_date.substring(0, 10)} onChange={handleChangeEndDate} />
                      </div>
                    </div>
                    <div className="mb-2">
                      <label className="form-label fs-7">{lang.labels.companyContact}:</label>
                      <Form.Select bsPrefix="form-select form-select-sm form-select-solid fw-bold" name="filter-contact" value={filter.contact} onChange={handleChangeContact}>
                        <option value="" className={`${contactsForCompany.length > 0 && "d-none"}`}>{lang.labels.selectOption}</option>
                        { contactsForCompany.map (( (item, index) => { return (
                          <option key={index} value={item.data.id}>{item.type === "person" ? `${item.data.first_name} ${item.data.last_name}` : `${item.data.name}`}</option>
                        )}))}
                      </Form.Select>
                    </div>
                    <div className="mb-2">
                      <label className="form-label fs-7">{lang.labels.treasuryAccount}:</label>
                      <Form.Select bsPrefix="form-select form-select-sm form-select-solid fw-bold" name="filter-treasury" value={filter.treasury_account} onChange={handleChangeTreasury}>
                        <option value="" className={`${treasuryAccountsForCompany.length > 0 && "d-none"}`}>{lang.labels.selectOption}</option>
                        { treasuryAccountsForCompany.map (( (item, index) => { return (
                          <option key={index} value={item.id}>{item.name}</option>
                        )}))}
                      </Form.Select>
                    </div>
                    <div className="mb-2">
                      <label className="form-label fs-7">{labels[sale].status}:</label>
                      { sale === "quote" &&
                        <Form.Select bsPrefix="form-select form-select-sm form-select-solid fw-bold" placeholder={lang.labels.selectOption} name="filter-status" value={filter.status} onChange={handleChangeStatus}>
                          <option value="" className="d-none">{lang.labels.selectOption}</option>
                          { statusQuoteList(lang).map (( (item, index) => { return (
                            <option key={index} value={item.code}>{item.description}</option>
                          )}))}
                        </Form.Select>
                      }
                      { sale === "order" &&
                        <Form.Select bsPrefix="form-select form-select-sm form-select-solid fw-bold" placeholder={lang.labels.selectOption} name="filter-status" value={filter.status} onChange={handleChangeStatus}>
                          <option value="" className="d-none">{lang.labels.selectOption}</option>
                          { statusOrderList(lang).map (( (item, index) => { return (
                            <option key={index} value={item.code}>{item.description}</option>
                          )}))}
                        </Form.Select>
                      }
                      { sale === "invoice" &&
                        <Form.Select bsPrefix="form-select form-select-sm form-select-solid fw-bold" placeholder={lang.labels.selectOption} name="filter-status" value={filter.status} onChange={handleChangeStatus}>
                          <option value="" className="d-none">{lang.labels.selectOption}</option>
                          { statusInvoiceList(lang).map (( (item, index) => { return (
                            <option key={index} value={item.code}>{item.description}</option>
                          )}))}
                        </Form.Select>
                      }
                    </div>
                    <div className="mb-2">
                      <label className="form-label fs-7">{labels[sale].expiration}:</label>
                      <Form.Select bsPrefix="form-select form-select-sm form-select-solid fw-bold" placeholder={lang.labels.selectOption} name="filter-expiration" value={filter.expiration} onChange={handleChangeExpiration}>
                        <option value="" className="d-none">{lang.labels.selectOption}</option>
                        { expirationList(lang).map (( (item, index) => { return (
                            <option key={index} value={item.code}>{item.description}</option>
                          )}))}
                      </Form.Select>
                    </div>
                    { (sale === "invoice") &&
                      <>
                        <div className="mb-2">
                          <label className="form-label fs-7">{labels[sale].validity}:</label>
                          <Form.Select bsPrefix="form-select form-select-sm form-select-solid fw-bold" placeholder={lang.labels.selectOption} name="filter-validity" value={filter.validity} onChange={handleChangeValidity}>
                            <option value="" className="d-none">{lang.labels.selectOption}</option>
                            { validationList(lang).map (( (item, index) => { return (
                              <option key={index} value={item.code}>{item.description}</option>
                            )}))}
                          </Form.Select>
                        </div>
                        <div className="mb-2">
                          <label className="form-label fs-7">{lang.labels.paymentStatus}:</label>
                          <Form.Select bsPrefix="form-select form-select-sm form-select-solid fw-bold" placeholder={lang.labels.selectOption} name="filter-payment-status" value={filter.payment} onChange={handleChangePayment}>
                            <option value="" className="d-none">{lang.labels.selectOption}</option>
                            { paymentStatusList(lang).map (( (item, index) => { return (
                              <option key={index} value={item.code}>{item.description}</option>
                            )}))}
                          </Form.Select>
                        </div>
                      </>
                    }
                    <div className="d-flex justify-content-end">
                      <Dropdown.Item bsPrefix="btn btn-sm btn-light btn-active-light-primary" type="reset" onClick={executeFilterReset}>{lang.labels.reset}</Dropdown.Item>
                      <Dropdown.Item bsPrefix="btn btn-sm btn-primary ms-3" type="button" onClick={executeFilterApply}>{lang.labels.apply}</Dropdown.Item>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown>
                <Dropdown.Toggle variant={`btn ${!button.group ? "btn-light-primary" : "btn-primary"} mb-1 me-2`}>
                  <div className="d-flex align-items-center">
                    <span className="svg-icon svg-icon-2 ms-0">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 21H8C7.4 21 7 20.6 7 20V4C7 3.4 7.4 3 8 3H16C16.6 3 17 3.4 17 4V20C17 20.6 16.6 21 16 21Z" fill="black"/>
                        <path opacity="0.3" d="M2 3H4C4.6 3 5 3.4 5 4V20C5 20.6 4.6 21 4 21H2V3ZM20 21H22V3H20C19.4 3 19 3.4 19 4V20C19 20.6 19.4 21 20 21Z" fill="black"/>
                      </svg>
                    </span>
                    {lang.labels.groupBy}
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu variant="menu menu-sub menu-sub-dropdown w-175px w-lg-200px" align="start">
                  <Dropdown.Divider />
                  <Dropdown.Item bsPrefix="dropdown-item fw-bold m-0 py-1" type="button" active={filter.group === "customer" ? true : false} onClick={() => executeGroupList("customer")}>{lang.labels.customer}</Dropdown.Item>
                  <Dropdown.Item bsPrefix="dropdown-item fw-bold m-0 py-1" type="button" active={filter.group === "treasury" ? true : false} onClick={() => executeGroupList("treasury")}>{lang.labels.treasury}</Dropdown.Item>
                  <Dropdown.Item bsPrefix="dropdown-item fw-bold m-0 py-1" type="button" active={filter.group === "status" ? true : false} onClick={() => executeGroupList("status")}>{labels[sale].status}</Dropdown.Item>
                  <Dropdown.Item bsPrefix="dropdown-item fw-bold m-0 py-1" type="button" active={filter.group === "expiration" ? true : false} onClick={() => executeGroupList("expiration")}>{labels[sale].expiration}</Dropdown.Item>
                  { sale === "invoice" &&
                    <Dropdown.Item bsPrefix="dropdown-item fw-bold m-0 py-1" type="button" active={filter.group === "validity" ? true : false} onClick={() => executeGroupList("validity")}>{labels[sale].validity}</Dropdown.Item>
                  }
                  { sale === "invoice" &&
                    <Dropdown.Item bsPrefix="dropdown-item fw-bold m-0 py-1" type="button" active={filter.group === "payment" ? true : false} onClick={() => executeGroupList("payment")}>{lang.labels.paymentStatus}</Dropdown.Item>
                  }
                  <Dropdown.Item bsPrefix="dropdown-item fw-bold m-0 py-1" type="button" active={filter.group === "year" ? true : false} onClick={() => executeGroupList("year")}>{lang.labels.year}</Dropdown.Item>
                  <Dropdown.Item bsPrefix="dropdown-item fw-bold m-0 py-1" type="button" active={filter.group === "half" ? true : false} onClick={() => executeGroupList("half")}>{lang.labels.halfYear}</Dropdown.Item>
                  <Dropdown.Item bsPrefix="dropdown-item fw-bold m-0 py-1" type="button" active={filter.group === "trimester" ? true : false} onClick={() => executeGroupList("trimester")}>{lang.labels.trimester}</Dropdown.Item>
                  <Dropdown.Item bsPrefix="dropdown-item fw-bold m-0 py-1" type="button" active={filter.group === "bimester" ? true : false} onClick={() => executeGroupList("bimester")}>{lang.labels.bimester}</Dropdown.Item>
                  <Dropdown.Item bsPrefix="dropdown-item fw-bold m-0 py-1" type="button" active={filter.group === "month" ? true : false} onClick={() => executeGroupList("month")}>{lang.labels.month}</Dropdown.Item>
                  <Dropdown.Item bsPrefix="dropdown-item fw-bold m-0 py-1" type="button" active={filter.group === "week" ? true : false} onClick={() => executeGroupList("week")}>{lang.labels.week}</Dropdown.Item>
                  <Dropdown.Item bsPrefix="dropdown-item fw-bold m-0 py-1" type="button" active={filter.group === "day" ? true : false} onClick={() => executeGroupList("day")}>{lang.labels.day}</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item bsPrefix="dropdown-item fw-bolder m-0 py-1" type="button" onClick={() => executeGroupList("")}>{lang.labels.doNotGroup}</Dropdown.Item>
                  <Dropdown.Divider />
                </Dropdown.Menu>
              </Dropdown>
              <button className="btn btn-primary mb-1 me-2" type="button" onClick={executeShowModalExport}>
                <div className="d-flex align-items-center">
                  <span className="svg-icon svg-icon-2 ms-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="black" />
                      <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="black" />
                      <path d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#C4C4C4" />
                    </svg>
                  </span>
                  {lang.labels.export}
                </div>
              </button>
              <button className="btn btn-primary mb-1 me-0" type="button" onClick={executeNavigateCreate}>
                <div className="d-flex align-items-center">
                  <span className="svg-icon svg-icon-2 ms-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                      <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                      <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                    </svg>
                  </span>
                  {labels[sale].add}
                </div>
              </button>
            </div>
          </div>
          { storage && button.filter &&
            <div className="card-scroll d-flex bg-light-primary rounded border-0 p-3 mx-7 mb-4">
              <button className={`btn btn-sm btn-danger text-nowrap fs-8 px-2 py-1 mx-1 ${storage.interval.length === 0 && "d-none"}`} type="button" onClick={() => executeFilterRemove("interval")}>
                <AppRemove attribute={lang.labels.interval} description={storage.interval}></AppRemove>
              </button>
              <button className={`btn btn-sm btn-danger text-nowrap fs-8 px-2 py-1 mx-1 ${storage.start_date.length === 0 && "d-none"}`} type="button" onClick={() => executeFilterRemove("start_date")}>
                <AppRemove attribute={lang.labels.start} description={dateFormatAbbreviated(storage.start_date)}></AppRemove>
              </button>
              <button className={`btn btn-sm btn-danger text-nowrap fs-8 px-2 py-1 mx-1 ${storage.end_date.length === 0 && "d-none"}`} type="button" onClick={() => executeFilterRemove("end_date")}>
                <AppRemove attribute={lang.labels.end} description={dateFormatAbbreviated(storage.end_date)}></AppRemove>
              </button>
              <button className={`btn btn-sm btn-danger text-nowrap fs-8 px-2 py-1 mx-1 ${storage.contact.length === 0 && "d-none"}`} type="button" onClick={() => executeFilterRemove("contact")}>
                <AppRemove attribute={lang.labels.contact} description={contactSelected(lang, contactsForCompany, storage.contact)}></AppRemove>
              </button>
              <button className={`btn btn-sm btn-danger text-nowrap fs-8 px-2 py-1 mx-1 ${storage.treasury_account.length === 0 && "d-none"}`} type="button" onClick={() => executeFilterRemove("treasury_account")}>
                <AppRemove attribute={lang.labels.treasury} description={treasuryAccountSelected(lang, treasuryAccountsForCompany, storage.treasury_account)}></AppRemove>
              </button>
              <button className={`btn btn-sm btn-danger text-nowrap fs-8 px-2 py-1 mx-1 ${storage.status.length === 0 && "d-none"}`} type="button" onClick={() => executeFilterRemove("status")}>
                <AppRemove attribute={lang.labels.status} description={statusRecordValue(lang, storage.status)}></AppRemove>
              </button>
              <button className={`btn btn-sm btn-danger text-nowrap fs-8 px-2 py-1 mx-1 ${storage.expiration.length === 0 && "d-none"}`} type="button" onClick={() => executeFilterRemove("expiration")}>
                <AppRemove attribute={lang.labels.expiration} description={expirationValue(lang, storage.expiration)}></AppRemove>
              </button>
              { storage.validity &&
                <button className={`btn btn-sm btn-danger text-nowrap fs-8 px-2 py-1 mx-1 ${storage.validity.length === 0 && "d-none"}`} type="button" onClick={() => executeFilterRemove("validity")}>
                  <AppRemove attribute={lang.labels.validity} description={validationValue(lang, storage.validity)}></AppRemove>
                </button>
              }
              { storage.payment &&
                <button className={`btn btn-sm btn-danger text-nowrap fs-8 px-2 py-1 mx-1 ${storage.payment.length === 0 && "d-none"}`} type="button" onClick={() => executeFilterRemove("payment")}>
                  <AppRemove attribute={lang.labels.payment} description={paymentStatusValue(lang, storage.payment)}></AppRemove>
                </button>
              }
            </div>
          }
        </>
      }
      <AppModalQuoteExport companyForUser={companyForUser} grouped={{state: button.group, desc: filter.group}} quotes={records as Quote[]} quoteGroups={recordGroups as QuoteGroup[]}></AppModalQuoteExport>
      <AppModalOrderExport companyForUser={companyForUser} grouped={{state: button.group, desc: filter.group}} orders={records as Order[]} orderGroups={recordGroups as OrderGroup[]}></AppModalOrderExport>
      <AppModalInvoiceExport companyForUser={companyForUser} grouped={{state: button.group, desc: filter.group}} invoices={records as Invoice[]} invoiceGroups={recordGroups as InvoiceGroup[]}></AppModalInvoiceExport>
    </>
  )
};

export default AppSaleRecordList;
