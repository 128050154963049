import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import Cookies from 'universal-cookie';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/translations/response';
import { AuthenticationDL } from '../../services/authentication.service';
import { Error } from '../../models/error.model';
import { Token } from '../../models/token.model';
import { legendInvalidIcon } from '../../tools/legend.data.entry.tool';
import { authUserLoggedIn } from '../../scripts/auth.user.script';
import { evaluateLegendValidateRequiredIcon } from '../../scripts/validate.legend.script';
import { expressions } from '../../libraries/regular.expressions.library';

import AppLegend from '../../components/element/Legend';

export interface LoginPageProps {};

let errorResponse: Error, tokenResponse: Token;

const LoginPage: React.FunctionComponent<LoginPageProps> = props => {
  const {lang} = StateLanguage()
  const navigate = useNavigate()
  const cookies = new Cookies()

  const [mounted, setMounted] = useState(false)
  const [loadIndicator, setLoadIndicator] = useState('off')
  const [email, setEmail] = useState({value: '', valid: false})
  const [password, setPassword] = useState({value: '', valid: false})

  const submitLogin = async (event: React.ChangeEvent <HTMLFormElement>) => {
    event.preventDefault()
    setLoadIndicator('on')

    if (email.valid && password.valid) {
      let apiKey: string = (cookies.get('app_service')) ? cookies.get('app_service').api_key : process.env.REACT_APP_SERVICE_API_KEY

      await AuthenticationDL.login(apiKey, email.value, password.value).then( (response) => {
        if (response.status === 200) {
          tokenResponse = response.data

          cookies.set('token', tokenResponse.authorization, {path: '/', sameSite: 'lax'})
          cookies.set('expires_at', tokenResponse.expires_at, {path: '/', sameSite: 'lax'})
          cookies.set('app_service', tokenResponse.app_service, {path: '/', sameSite: 'lax'})
          cookies.set('email', email.value, {path: '/', sameSite: 'lax'})

          Swal.fire({
            title: lang.labels.successfullyLoggedIn,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            navigate('/app/main', {replace: true})
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.code),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorstryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.okGotIt,
            customClass: {confirmButton: 'btn btn-primary'}
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        if (email.value.length === 0) {
          legendInvalidIcon('input-email', 'container-validate-email-required')
        }
        if (password.value.length === 0) {
          legendInvalidIcon('input-password', 'container-validate-password-required')
        }
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeEmail = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setEmail({...email, value: event.target.value})
  }

  const handleChangePassword = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPassword({...password , value: event.target.value})
  }

  const validateEmail = () => {
    evaluateLegendValidateRequiredIcon(expressions.email, email, setEmail, 'input-email', 'container-validate-email-valid', 'container-validate-email-required')
  }

  const validatePassword = () => {
    evaluateLegendValidateRequiredIcon(expressions.limit, password, setPassword, 'input-password', 'container-validate-password-valid', 'container-validate-password-required')
  }

  useEffect( () => {
    setMounted(true)

    if (cookies.get('email')) {
      setEmail({value: cookies.get('email'), valid: true})
    }

    if (!authUserLoggedIn()) {
      switch (true) {
        case (!!cookies.get('token') && !!cookies.get('expires_at') && !!cookies.get('app_service') && !!cookies.get('company') && !!cookies.get('email')):
          toast.warn(lang.labels.yourSessionHasExpired)
          break;
        case (!!cookies.get('email')):
          toast.error(lang.labels.yourSessionHasBeenClosed)
          break;
      }
    } 

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-lg-500px bg-body rounded shadow-sm p-10 mx-auto">
      <form className="form w-100" noValidate onSubmit={submitLogin}>
        <div className="text-center mb-10">
          <h1 className="text-dark text-capitalize mb-3">{lang.labels.signIn}</h1>
          <div className="text-gray-400 fw-bold fs-4">
            {lang.labels.newHere}
            <Link to="/auth/register" className="link-primary fw-bolder ms-2">{lang.labels.createAnAccount}</Link>
          </div>
        </div>
        <div className="fv-row mb-5">
          <label className="form-label fs-6 fw-bolder text-dark">{lang.labels.email}</label>
          <input id="input-email" className="form-control form-control form-control-solid" type="email" name="email" autoComplete="off" value={email.value} onChange={handleChangeEmail} onKeyUp={validateEmail} onBlur={validateEmail} />
          <AppLegend component={null} attribute={{validity: email.valid, name: "email", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
        </div>
        <div className="fv-row mb-10">
          <div className="d-flex flex-stack mb-2">
            <label className="form-label fs-6 fw-bolder text-dark">{lang.labels.password}</label>
            <Link to="/auth/recover" className="link-primary fs-6 fw-bolder">{lang.labels.forgotPassword}</Link>
          </div>
          <input id="input-password" className="form-control form-control-lg form-control-solid" type="password" name="password" autoComplete="off" value={password.value} onChange={handleChangePassword} onKeyUp={validatePassword} onBlur={validatePassword} />
          <AppLegend component={null} attribute={{validity: password.valid, name: "password", index: null, sub_index: null}} container={{valid: true, required: true, size: false, type: false, identical: false, exists: false, max: false}}></AppLegend>
        </div>
        <div className="text-center">
          <button className="btn btn-lg btn-primary w-100 mb-5" type="submit" data-kt-indicator={loadIndicator}>
            <span className="indicator-label fw-bolder">{lang.labels.continue}</span>
            <span className="indicator-progress">
              {lang.labels.pleaseWait}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </button>
        </div>
      </form>
    </div>
  )
};

export default LoginPage;
